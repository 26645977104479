import React from 'react'
import { useState } from 'react'
import {
    generateReservationCSVReport,
    generateRevenueCSVReport,
} from "../service/AdminApi/resevationApi";
import { downloadCSV, getStartDateOfCurrentMonth, getStartDateOfCurrentWeek } from "../utils/utils";

const ReservationReportCard = ({ partnerData, index }) => {


    const [showCancel, setShowCancel] = useState(false)

    return (
        <>



            <div className="relative">
                <div className="flex items-center p-list-holder justify-between gap-10 overflow-x-auto">
                    <div className="p-list flex items-center gap-3">
                        <p className='min-w-[25px]'>{index}</p>
                        <div className="flex flex-col">
                            <h4 className='text-[#1C1C1C] flex items-center gap-2 font-[600] text-[20px]'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_2066_13853" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="21">
                                    <path d="M12 22.5C12 22.5 20 16.5 20 10C20 5.858 16.4185 2.5 12 2.5C7.5815 2.5 4 5.858 4 10C4 16.5 12 22.5 12 22.5Z" fill="white" stroke="white" stroke-linejoin="round" />
                                    <path d="M10.5 7.5V15.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10.5 7.5H13.5C14.0304 7.5 14.5391 7.71071 14.9142 8.08579C15.2893 8.46086 15.5 8.96957 15.5 9.5C15.5 10.0304 15.2893 10.5391 14.9142 10.9142C14.5391 11.2893 14.0304 11.5 13.5 11.5H10.5V7.5Z" fill="black" stroke="black" stroke-linejoin="round" />
                                </mask>
                                <g mask="url(#mask0_2066_13853)">
                                    <path d="M0 0.5H24V24.5H0V0.5Z" fill="#2EABE3" />
                                </g>
                            </svg>
                                {partnerData?.name}</h4>
                            <p className='text-[#1C1C1C] text-[16px] flex items-center gap-2'>
                                {partnerData?.airport?.name} - ({partnerData?.airport?.code})
                            </p>
                        </div>
                    </div>


                    <div className="flex items-center gap-10">

                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{partnerData?.reservations_count ? partnerData?.reservations_count : '0'}</span>
                            <p className='text-[#7A7A7A] text-[12px]'>Total Reservations</p>
                        </div>
                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${partnerData?.total_revenue ? partnerData?.total_revenue : '0'}</span>
                            <p className='text-[#7A7A7A] text-[12px]'>Total Revenue</p>
                        </div>
                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{partnerData?.commission ? partnerData?.commission : '0%'}%</span>
                            <p className='text-[#7A7A7A] text-[12px]'>Commission</p>
                        </div>
                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] bg-[#FEE9F2]  text-[#ED1A75] text-[16px]'>{partnerData?.cancel_reservations_count ? partnerData?.cancel_reservations_count : '0'} </span>
                            <p className='text-[#7A7A7A] text-[12px]'>Cancelation</p>
                        </div>

                        <button type='button' className='bg-[#F2F2F2] w-[24px] h-[24px] min-w-[24px] rounded-[5px] flex items-center justify-center'
                            onClick={() => {
                                setShowCancel(!showCancel)
                            }}
                        >
                            <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.8339 1.91649C2.83396 2.10257 2.79737 2.28684 2.72621 2.45877C2.65506 2.63071 2.55074 2.78694 2.4192 2.91856C2.28767 3.05018 2.1315 3.1546 1.95961 3.22586C1.78772 3.29713 1.60348 3.33384 1.4174 3.3339C1.23133 3.33396 1.04706 3.29737 0.875126 3.22621C0.703191 3.15506 0.546954 3.05074 0.415336 2.9192C0.283718 2.78767 0.179297 2.6315 0.108033 2.45961C0.0367699 2.28772 5.98432e-05 2.10348 7.32043e-08 1.9174C-0.000120638 1.54161 0.149049 1.18115 0.414693 0.915337C0.680337 0.649522 1.04069 0.500121 1.41649 0.5C1.79229 0.499879 2.15275 0.649049 2.41856 0.914693C2.68437 1.18034 2.83378 1.5407 2.8339 1.91649Z" fill="#7A7A7A" />
                                <path d="M1.41649 8.19627C2.1988 8.19627 2.83299 7.56208 2.83299 6.77978C2.83299 5.99747 2.1988 5.36328 1.41649 5.36328C0.634185 5.36328 0 5.99747 0 6.77978C0 7.56208 0.634185 8.19627 1.41649 8.19627Z" fill="#7A7A7A" />
                                <path d="M1.41649 13.0595C2.1988 13.0595 2.83299 12.4254 2.83299 11.6431C2.83299 10.8607 2.1988 10.2266 1.41649 10.2266C0.634185 10.2266 0 10.8607 0 11.6431C0 12.4254 0.634185 13.0595 1.41649 13.0595Z" fill="#7A7A7A" />
                            </svg>
                        </button>
                    </div>
                </div>
                {showCancel &&
                    <div className="reservation-actions">
                        <button

                            onClick={async () => {
                                try {
                                    let partnerId = partnerData.id;

                                    const response = await generateRevenueCSVReport(
                                        partnerId,
                                        getStartDateOfCurrentMonth(),
                                        'monthly'
                                    );
                                    if (response) downloadCSV(response, getStartDateOfCurrentMonth()+'_monthly');
                                    else {
                                        alert('No data found')
                                    }
                                } catch (error) {
                                    alert('error')
                                }
                            }}
                            className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center"
                        >
                        Monthly Report
                        </button>
                        <button
                            onClick={async () => {
                                let partnerId = partnerData.id;
                                const response = await generateRevenueCSVReport(
                                    partnerId,
                                    getStartDateOfCurrentWeek(),
                                    'weekly'
                                );
                                if (response) downloadCSV(response,getStartDateOfCurrentWeek()+'_weekly');
                                else {
                                    alert('No data found')
                                }
                            }}
                            className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center"
                        >
                        Weekly Report
                        </button>

                    </div>
                }
                {showCancel &&
                    <div
                        onClick={() => {
                            setShowCancel(false)
                        }}
                        className=" absolute left-0 z-[1] top-0 w-full  h-[100vh]"></div>
                }
            </div>
        </>
    )
}

export default ReservationReportCard
