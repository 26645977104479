export const OPTIIONS_KEYS = {
  amenities_free_shuttle: "amenities_free_shuttle",
  amenities_vallet: "amenities_vallet",
  amenities_surviellance247: "amenities_surviellance247",
  amenities_open247: "amenities_open247",
  badges_near_airport: "badges_near_airport",
  badges_free_shuttle: "badges_free_shuttle",
  badges_hotdeal: "badges_hotdeal",
  badges_sale: "badges_sale",
  badges_high_rating: "badges_high_rating",
  badges_limitedSpace: "badges_limitedSpace",
  parkingTypes_coveredSelfPark: "parkingTypes_coveredSelfPark",
  parkingTypes_unCoveredSelfPark: "parkingTypes_unCoveredSelfPark",
  parkingTypes_coveredVallet: "parkingTypes_coveredVallet",
  parkingTypes_unCoveredVallet: "parkingTypes_unCoveredVallet",
};
