import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete, MarkerF as Marker } from '@react-google-maps/api';
import { Airport, Partner } from '../models/types';
import Spinner from './Spinner';
import AirportIcon from '../assets/images/airport-icon.png'
import ParkingIcon from '../assets/images/parking-icon.png'

const containerStyle = {
    width: '100%',
    height: '400px'
};

const fallBack_center = {
    lat: -3.745,
    lng: -38.523
};

type LocationPickerProps = {
    isOpen: boolean;
    onClose: () => void;
    loading: boolean
    partner: Partner
    onLocationSelected: (location: { lat: number; lng: number; address: string }) => void;
    defaultPosition?: { lat: number; lng: number; address: string };
};
const airportIcon = {
    path: `M12 0C7.032 0 3 4.032 3 9c0 3.6 1.8 8.1 5.4 13.5C10.8 22.5 11.4 24 12 24s1.2-1.5 3.6-1.5c3.6-5.4 5.4-10.8 5.4-13.5 0-4.968-4.032-9-9-9z`,

    fillOpacity: 1.0,
    scale: 1.5,
    strokeColor: 'white',
    strokeWeight: 0.5,
};

const LocationPicker: React.FC<LocationPickerProps> = ({ partner, loading, onClose, onLocationSelected, defaultPosition }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAAfEDhqDqqEO9unfhxcFQRdA2FVsm-97s',
        libraries: ['places']
    });

    const [markerPosition, setMarkerPosition] = useState(defaultPosition);
    const [airportMarker, setAirportMarker] = useState(fallBack_center);
    const [center, setCenter] = useState(fallBack_center);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const geocoder = useRef<google.maps.Geocoder | null>(null);
    const [locationName, setLocationName] = useState(defaultPosition?.address || '');

    const onLoad = useCallback((map: google.maps.Map) => {
        geocoder.current = new google.maps.Geocoder();
        if (defaultPosition) {
            map.setCenter({ lat: defaultPosition.lat, lng: defaultPosition.lng });
        } else {
            // const bounds = new window.google.maps.LatLngBounds(fallBack_center);
            // map.fitBounds(bounds);
        }
    }, [defaultPosition]);


    useEffect(() => {
        const airport = partner?.airport;

        if (airport) {
            if (airport.latlng) {
                const [lat, lng] = airport?.latlng.split(',')
                if (lat && lng) {

                    setAirportMarker({ lat: Number(lat), lng: Number(lng) })
                    // setMarkerPosition({ lat: Number(lat), lng: Number(lng) })
                    setCenter({ lat: Number(lat), lng: Number(lng) })

                }
            }
        }

        if (partner.latlng) {
            const [lat, lng] = partner?.latlng.split(',')
            if (lat && lng) {


                setMarkerPosition({ lat: Number(lat), lng: Number(lng) })
                setCenter({ lat: Number(lat), lng: Number(lng) })

            }
        }

    }, [partner])
    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const location = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            setMarkerPosition(location);
            // setCenter(location)
            geocoder.current?.geocode({ location }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    setLocationName(results[0].formatted_address);
                } else {
                    setLocationName('Unknown location');
                }
            });
        }
    };

    const onPlaceChanged = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place.geometry) {
                const newPosition = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMarkerPosition(newPosition);
                setLocationName(place.formatted_address || 'Unknown location');
            }
        }
    };

    const handleSelectLocation = () => {
        onLocationSelected({ lat: markerPosition.lat, lng: markerPosition.lng, address: locationName });
        onClose();
    };

    return isLoaded && true ? (
        <div>
            <div></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] w-full ">
               
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center ?? fallBack_center}
                    zoom={14}
                    onLoad={onLoad}
                    onClick={handleMapClick}
                >
                    <Marker position={markerPosition} icon={ParkingIcon} />
                    {airportMarker && <Marker position={airportMarker} icon={AirportIcon} />}
                </GoogleMap>

                <div className="flex w-full mt-4 gap-2">
 <Autocomplete
                    onLoad={(ref) => (autocompleteRef.current = ref)}
                    onPlaceChanged={onPlaceChanged}
                    className='w-full'
                >
                    <input
                        type="text"
                        placeholder="Search for a location"
                        style={{
                            boxSizing: 'border-box',
                            border: '1px solid transparent',
                            width: '100%',
                            height: '40px',
                            padding: '0 12px',
                            borderRadius: '3px',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                            fontSize: '14px',
                            outline: 'none',
                            textOverflow: 'ellipses',
                            marginBottom: '10px'
                        }}
                    />
                </Autocomplete>

                
                {!loading && <button
                    onClick={handleSelectLocation}
                    className="flex items-center gap-2 justify-center whitespace-nowrap  bg-[black] w-fit text-[16px] font-[600] text-[white] rounded-[10px] py-2 px-6  mb-10"
                >
                    Select Location
                </button>}
                {loading && <Spinner />}
                </div>
            </div>
        </div>
    ) : null;
};

export default React.memo(LocationPicker);
