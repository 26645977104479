import { api, BASE_URL } from "../api";

export const createPartner = (params,emailNote, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + "/admin/partners", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name: params.name,
      airport_id: params.airport_id,
      commission: params.commission,
      email: params.email,
      contact_email: params.contact_email,
      phone_number: params.phone_number,
      first_name: params.first_name,
      last_name: params.last_name,
      // information: params.information,
      address: params.address,
      map_link: params.map_link,
      // description: params.description,
      email_msg: emailNote?emailNote:'',
      // password: params.password,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        response.json().then((resp) => {
          onResponse(false, resp.error);
        });

        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const editPartner = (params,emailNote, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(
    BASE_URL + `/admin/partners/${params.id}?airport_id=${params.airport_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...(params.name && { name: params.name }),
        ...(params.airport_id && { airport_id: params.airport_id }),
        ...(params.commission && { commission: params.commission }),
        ...(params.email && { email: params.email }),
        ...(params.email && { contact_email: params.contact_email }),

        ...(params.phone_number && { phone_number: params.phone_number }),
        ...(params.first_name && { first_name: params.first_name }),
        ...(params.last_name && { last_name: params.last_name }),
        ...(params.address && { address: params.address }),
        ...(params.map_link && { map_link: params.map_link }),
        ...(emailNote && { email_msg: emailNote }),
      }),
    }
  )
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const getAdminPartners = async (airportId, searchquery, currentPage) => {
  let params = "";
  if (airportId) params += `&airport_id=${airportId}`;

  if (searchquery) params += `&q=${searchquery}`;
  if (currentPage) params += `&page=${currentPage}`;

  try {
    const resp = await api.get(`/admin/partners?per_page=10${params}`);

    return resp?.data;
  } catch (error) {
    return undefined;
  }
};
export const getAllPartnersData = async (
  airportId,
  searchquery,
  currentPage
) => {
  let params = "";
  if (airportId) params += `&airport_id=${airportId}`;

  if (searchquery) params += `&q=${searchquery}`;
  if (currentPage) params += `&page=${currentPage}`;

  try {
    const resp = await api.get(`/admin/partners?per_page=1000${params}`);
    return resp.data;
  } catch (error) {
    alert("erorr");
    return { error: "something went wrong!" };
  }
};

export const updatePartnerstatus = (statusParams, onResponse) => {
  const token = localStorage.getItem("token");

  fetch(BASE_URL + `/admin/partners/${statusParams.id}/active`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      active: statusParams.status + "",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const updatePartnerValues = (partnerId, params, onResponse) => {
  const token = localStorage.getItem("token");

  fetch(BASE_URL + `/admin/partners/${partnerId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const updatePartnerProfileImage = (
  partnerId,
  params,
  imageFile,
  onResponse
) => {
  const token = localStorage.getItem("token");

  const formData = new FormData();

  // Append the image file

  // Append other params
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  if (imageFile) {
    formData.append("profile_image", imageFile);
  }
  fetch(BASE_URL + `/admin/partners/${partnerId}`, {
    method: "PUT",
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const updatePartnerOptions = (partnerId, params, onResponse) => {
  const token = localStorage.getItem("token");

  fetch(BASE_URL + `/admin/partners/${partnerId}/options`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const updatePartnerDynamicInfo = (partnerId, params, onResponse) => {
  const token = localStorage.getItem("token");

  fetch(BASE_URL + `/admin/partners/${partnerId}/dynamic_info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
export const updatePartnerRanks = async (partnerIds, ranks) => {
  const token = localStorage.getItem("token");

  const response = await fetch(BASE_URL + "/admin/partners/rank", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ partner_ids: partnerIds, ranks: ranks }),
  });

  if (!response.ok) {
    throw new Error("Failed to update partner ranks");
  }

  return await response.json();
};
export const getAdminSinglePartner = async (partnerID) => {
  const resp = await api.get(`/admin/partners/${partnerID}`);
  return resp.data;
};

export const deletePartner = (id, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/partners/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};
