import { ReservationParams } from "../../models/api.types";
import { ReservationList } from "../../models/types";
import { api, BASE_URL } from "../api";
export const getPartnerReservations = async (
  partID,
  page,
  query,
  date,
  filter
) => {
  let params = "";
  if (query) {
    params += "&q=" + query;
  }
  if (date) {
    params += "&date=" + date;
  }
  if (filter) {
    params += "&filter_by=" + filter;
  }

  const resp = await api.get(
    `/admin/reservation?per_page=10&page=${page}&partner_id=${partID}${params}`
  );
  return resp.data;
};

export const getPartnerAllReservations = async (partID) => {
  let endPoint =
    partID != ""
      ? `/admin/reservation?per_page=100000&page=1&partner_id=${partID}`
      : "/admin/reservation?per_page=100000&page=1";

  const resp = await api.get(endPoint);
  return resp.data;
};

export const generateReservationPDFReport = async (
  partnerId?: string,
  fromDate?: string,
  todate?: string,
  airportId?: string
) => {
  let params = "";
  if (partnerId) params += `&partner_id=${partnerId}`;
  if (fromDate) params += `&from=${fromDate}`;
  if (todate) params += `&to==${todate}`;
  if (airportId) params += `&airport_id=${airportId}`;
  const resp = await api.get(`/admin/reports/reservation/export/pdf?${params}`);
  return resp.data;
};
export const generateReservationCSVReport = async (
  partnerId?: string,
  fromDate?: string,
  type?: string,
  airportId?: string
) => {
  let params = "";
  if (partnerId) params += `&partner_id=${partnerId}`;
  if (fromDate) params += `&from=${fromDate}`;
  if (type) params += `&type=${type}`;
  // if (todate) params += `&to==${todate}`;
  if (airportId) params += `&airport_id=${airportId}`;
  const resp = await api.get(`/admin/reports/reservation/export/csv?${params}`);
  return resp.data;
};

export const generateRevenueCSVReport = async (
  partnerId?: string,
  fromDate?: string,
  type?: string
  // todate?: string,
  // airportId?: string
) => {
  let params = "";
  if (partnerId) params += `&partner_id=${partnerId}`;
  if (fromDate) params += `&from=${fromDate}`;
  if (type) params += `&type=${type}`;
  // if (todate) params += `&to==${todate}`;
  // if (airportId) params += `&airport_id==${todate}`;
  const resp = await api.get(`/admin/reports/revenue/export/csv?${params}`);
  return resp.data;
};

export const cancleReserv = (params, onResponse) => {
  const token = localStorage.getItem("token");
  fetch(BASE_URL + `/admin/reservation/${params.id}/cancel`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      airport_id: params.airport_id,
      partner_id: params.partner_id,
      reason: params.reason,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        onResponse(false);
        return null;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        onResponse(true, data);
      }
    })
    .catch((error) => {
      onResponse(false);
      console.error("There was a problem with the fetch operation:", error);
    });
};

export const getReservationInfo = async (partner_id?: string) => {
  // const resp = await api.get("/admin/reservation/daily/info?airport_id=1");
  let token = localStorage.getItem("token");
  let param = "";
  if (partner_id) {
    param = `partner_id=${partner_id}`;
  }
  const response = await fetch(
    BASE_URL + `/admin/reservation/daily/info?${param}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

export const partnerOverView = async (partner_id?: string) => {
  // const resp = await api.get("/admin/reservation/daily/info?airport_id=1");
  let token = localStorage.getItem("token");
  let param = "";
  if (partner_id) {
    param = `?partner_id=${partner_id}`;
  }
  const response = await fetch(BASE_URL + `/admin/partnerOverview${param}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

export const getAdminReservations = async (
  params: ReservationParams
): Promise<ReservationList> => {
  // const resp = await api.get("/admin/reservation?airport_id=1");
  let token = localStorage.getItem("token");
  const { page, search, date, filter, airport_id, partner_id } = params;
  let pageData = "";
  if (airport_id) {
    pageData += "&airport_id=" + airport_id;
  }
  if (partner_id) {
    pageData += "&partner_id=" + partner_id;
  }
  if (page) {
    pageData += "&page=" + page;
  }
  if (search) {
    pageData += `&q=${search}`;
  }
  if (date) {
    pageData += `&date=${date}`;
  }
  if (filter) {
    pageData += `&filter_by=${filter}`;
  }
  const response = await fetch(
    BASE_URL + `/admin/reservation?&per_page=10${pageData}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  try {
    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("Reservation not found");
  }
};
