import React from 'react'
import ReservRevenuBox from './ReservRevenuBox'

const Revenue = ({today, weekly,monthy}) => {
  return (
    <div className="w-full p-[20px] rounded-[20px] bg-white">
      <div className="flex justify-between items-center">
        <h4 className='md:text-[20px] text-[16px] text-[#1C1C1C] font-[400]'>Revenue</h4>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_875_10295)">
            <path d="M18.4815 12.4998C17.6426 11.7966 16.576 11.4233 15.4815 11.4498H8.44655C8.12579 11.4579 7.8067 11.4012 7.50837 11.2831C7.21005 11.165 6.93862 10.9879 6.71034 10.7624C6.48207 10.537 6.30163 10.2677 6.17984 9.97089C6.05805 9.67404 5.99741 9.35567 6.00155 9.03484C5.99373 8.90997 5.99373 8.78472 6.00155 8.65984C6.09613 8.08655 6.39674 7.5675 6.84694 7.20017C7.29713 6.83283 7.86594 6.64248 8.44655 6.66484H15.3765C15.9418 6.66435 16.4889 6.86465 16.9203 7.23005C17.3516 7.59544 17.6391 8.10216 17.7315 8.65984H19.9815C19.8874 7.50325 19.3614 6.42445 18.5081 5.63793C17.6549 4.85141 16.537 4.41478 15.3765 4.41484H13.0065V0.964844H11.1315V4.41484H8.44655C7.28612 4.41478 6.16817 4.85141 5.31495 5.63793C4.46173 6.42445 3.93574 7.50325 3.84155 8.65984C3.83407 8.78473 3.83407 8.90996 3.84155 9.03484C3.84155 9.63958 3.96066 10.2384 4.19208 10.7971C4.42351 11.3558 4.76271 11.8635 5.19032 12.2911C6.05393 13.1547 7.22523 13.6398 8.44655 13.6398H15.5565C15.8748 13.6318 16.1914 13.6876 16.4877 13.8039C16.784 13.9201 17.0541 14.0945 17.2819 14.3168C17.5098 14.5391 17.6908 14.8047 17.8144 15.0981C17.938 15.3914 18.0016 15.7065 18.0015 16.0248C18.0171 16.1945 18.0171 16.3652 18.0015 16.5348C17.8825 17.0626 17.5874 17.5342 17.1649 17.8722C16.7425 18.2102 16.2176 18.3945 15.6765 18.3948H8.62655C8.0855 18.3945 7.56064 18.2102 7.13815 17.8722C6.71566 17.5342 6.42065 17.0626 6.30155 16.5348H4.03655C4.16546 17.6619 4.7045 18.7021 5.55097 19.4573C6.39744 20.2125 7.49217 20.6298 8.62655 20.6298H11.1315V24.0348H13.0065V20.6298H15.5565C16.6909 20.6298 17.7857 20.2125 18.6321 19.4573C19.4786 18.7021 20.0176 17.6619 20.1465 16.5348V16.0248C20.1447 15.3511 19.9946 14.686 19.7069 14.0768C19.4191 13.4676 19.0008 12.9292 18.4815 12.4998Z" fill="#2EABE3" />
          </g>
          <defs>
            <clipPath id="clip0_875_10295">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>

      </div>

      <div className="flex gap-4 flex-col mt-4">
        <ReservRevenuBox Symbol={true} Ratio=".96"  Count={Number(today).toFixed(2)} BoxTag='Today' />
        <ReservRevenuBox Symbol={true} Ratio=".72" Count={Number(weekly).toFixed(2)} BoxTag='This Week' />
        <ReservRevenuBox Symbol={true} Ratio=".88" Count={Number(monthy).toFixed(2)} BoxTag='This Month' />
      </div>
    </div>
  )
}

export default Revenue
