import React, { useState } from 'react'
import BusinessSec from '../view-partner-components/BusinessSec'

const PartnerBusinessSec = ({ partnerData }) => {

    const [showBusinessInfoSec, setShowBusinessInfoSec] = useState(true)
    return (
        <>

            <div className="mt-3">
                <BusinessSec partnerData={partnerData} />
            </div>

        </>

    )
}

export default PartnerBusinessSec
