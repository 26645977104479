import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import { cancleReserv } from '../service/AdminApi/resevationApi';
import { Reservation } from '../models/types';

type CancleReservationProps = {
    data: Reservation
    onDeleteSuccess: () => void
    onCloseModal: () => void
}
const CancleReservation = (props: CancleReservationProps) => {
    const { data, onDeleteSuccess, onCloseModal } = props;


    const { dispatch: globalDispatch } = React.useContext(GlobalContext);





    const schema = yup
        .object({
            reason: yup.string().required(),
            id: yup.number().required(),
            check_in: yup.string().required(),
            check_out: yup.string().required(),
            airport_id: yup.string().required(),
            partner_id: yup.string().nullable(),
        })
        .required();

    const {
        register,
        handleSubmit,
        setError,
        setValue,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: any) => {

        cancleReserv(data, (success: boolean, resp: any) => {
            if (success) {
                onDeleteSuccess()
                onCloseModal()
                showToast(globalDispatch, "Reservation cancelled");
            } else {
                alert('something went wrong!')
            }

        });

    };




    React.useEffect(function () {

        setValue("check_in", data.check_in);
        setValue("check_out", data.check_out);
        setValue("id", data.id);
        setValue("partner_id", data.partner_id);
        setValue("airport_id", data.airport_id);


    }, []);







    console.log(errors);


    return (
        <div className="modal-holder ">
            <div className="modal-outer" onClick={() => { onCloseModal() }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">

                <h4 className='sm:text-[24px] text-[18px] text-[#2B2B2B] font-[400]'>Are you sure? You want to cancel this reservation?</h4>




                <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-4">
                        <div className="popup-field-box">
                            <input
                                {...register("id")}
                                type='hidden' />
                            <input
                                {...register("airport_id")}
                                type='hidden' />
                            <input
                                {...register("partner_id")}
                                type='hidden' />

                            <label>Enter Reason</label>
                            <input
                                {...register("reason")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">

                                {errors.reason?.message}
                            </p>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-6">
                            <div className="popup-field-box">
                                <label>Reservation #</label>
                                <input
                                    {...register("id")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.id?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Check in</label>
                                <input
                                    {...register("check_in")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.check_in?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Check out</label>
                                <input
                                    {...register("check_out")}
                                    type='text' readOnly />
                                <p className="text-red-500 text-xs italic">
                                    {errors.check_out?.message}
                                </p>
                            </div>
                        </div>



                        <div className="flex gap-3">
                            <button type='button' onClick={() => { onCloseModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Nope</button>
                            <button type="submit" className='bg-[#2EABE3] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                                Yes, Cancel
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CancleReservation
