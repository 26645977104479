import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { EditTax, addTax } from "../service/AdminApi/AdminApi";
import { GlobalContext, showToast } from "../globalContext";
import { Partner, PartnerTax } from "../models/types";

type AddTaxeProps = {
  closeModal: () => void,
  onRefresh: () => void
  isEdit?: boolean
  data: Partner
  tax?: PartnerTax
}

const AddTaxe = (props: AddTaxeProps) => {
  const { closeModal, onRefresh, data, isEdit, tax } = props
  // { closeModal, getData, partnerData }
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [isPercentage, setIsPercentage] = useState("1");

  const schema = yup.object({
    name: yup.string().required(),
    // is_percentage: yup.string(),
    type: yup.string().required(),
    partner_id: yup.string().required(),
    airport_id: yup.string().required(),
    ...(isPercentage === "1" && {
      percentage: yup.number().max(100, "Value must not exceed 100"),
    }),
    ...(isPercentage === "0" && {
      amount: yup.string(), // Add validation for amount if needed
    }),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (!isEdit) {
      addTax(data, (success, resp) => {
        if (success) {

          onRefresh()
          closeModal();
          showToast(globalDispatch, "Tax Added");
        } else {
          alert("something went wrong!");
        }
      });
    }
    if (isEdit) {
      EditTax(data, (success, resp) => {
        if (success) {
          onRefresh()
          closeModal();
          showToast(globalDispatch, "Tax Edited");
        } else {
          alert("something went wrong!");
        }
      });
    }
  };

  useEffect(function () {

    setValue("name", tax?.name);
    // setValue("is_percentage", OffPercentage !== null? OffPercentage : null);
    setValue("type", tax?.percentage == "0" ? "0" : "1");
    setValue("amount", tax?.amount);
    setValue("percentage", tax?.percentage);
    setValue("id", tax?.id);
    setIsPercentage(tax?.percentage == "0" ? "0" : "1")
    setValue("partner_id", data?.id);
    setValue("airport_id", data?.airport_id);
  }, []);

  return (
    <div className="modal-holder">
      <div
        className="modal-outer"
        onClick={() => {
          closeModal();
        }}
      ></div>
      <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
        {!isEdit && <h4 className="text-[24px] text-[#2B2B2B] font-[400]">
          Add New Tax/Fee
        </h4>}
        {isEdit && <h4 className="text-[24px] text-[#2B2B2B] font-[400]">
          Edit {tax?.name}
        </h4>
        }
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="popup-field-box">
              <label>Enter Title</label>
              <input {...register("partner_id")} type="hidden" />
              <input {...register("airport_id")} type="hidden" />
              <input {...register("name")} type="text" />
              <p className="text-red-500 text-xs italic">
                {errors.name?.message}
              </p>
            </div>

            <div className="popup-field-box">
              <label>Select charge type</label>
              <input type="hidden" {...register("type")} value={isPercentage} />
              {/* <input type='hidden'
                                {...register("is_percentage")}
                                value={isPercentage}
                            /> */}

              <div className="percent-field-holder">
                <select
                  onChange={(e) => {
                    setValue("type", e.target.value);
                    setValue("is_percentage", e.target.value);

                    setIsPercentage(e.target.value);
                  }}
                  value={isPercentage}
                >
                  <option value="1">Percentage</option>
                  <option value="0">Flat</option>
                </select>
                {isPercentage === "1" ? (
                  <input
                    {...register("percentage")}
                    placeholder="0.00%"
                    type="text"
                  />
                ) : (
                  <></>
                )}
                {isPercentage === "0" ? (
                  <input
                    {...register("amount")}
                    placeholder="$20"
                    type="text"
                  />
                ) : (
                  <></>
                )}
              </div>
              <p className="text-red-500 text-xs italic">
                {errors.percentage?.message}
              </p>
              <p className="text-red-500 text-xs italic">
                {errors.amount?.message}
              </p>
            </div>
            <p className="text-red-500 text-xs italic">
              {errors.type?.message}
            </p>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => {
                  closeModal();
                }}
                className="py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#2EABE3] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]"
              >
                {isEdit ? "Edit " : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaxe;
