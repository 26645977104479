import React, { useEffect } from "react";
import { useState } from "react";
import RevervationListCard from "./RevervationListCard";
import Pagination from "./Pagination";
import { generateReservationCSVReport, generateReservationPDFReport, getAdminReservations, getReservationInfo } from "../service/AdminApi/resevationApi";
import { Reservation } from "../models/types";
import { ReservationInfo } from "../models/api.types";
import { downloadCSV, downloadPDF, getStartDateOfCurrentMonth, getStartDateOfCurrentWeek } from "../utils/utils";
import { getAdminAirports } from '../service/AdminApi/AdminApi';
import { getAdminPartners, getAllPartnersData } from "../service/AdminApi/partnerApi";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import Loader from "./Loader";




const AllReservations = ({ partnerReservation }) => {

  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckOut] = useState(false);

  const [filter, setFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [airportBox, setAirportBox] = useState([])
  const [partnerBox, setPartnerBox] = useState([])
  const [airportId, setairPortId] = useState('')
  const [partnerId, setPartnerId] = useState('')
  const [search, setSearchQuery] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState('');



  const [reservationList, setReservationList] = useState<Reservation[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  const { user } = React.useContext(AuthContext).state;

  const getAirport = async () => {

    const airportData = await getAdminAirports();
    setTimeout(() => {
      setAirportBox(airportData.data);

    }, 200);

  };

  const getPartner = async (airportId) => {

    const partnerData = await getAllPartnersData(airportId);
    setTimeout(() => {
      setPartnerBox(partnerData.data);

    }, 200);

  };


  React.useEffect(() => {

    getAirport()
  }, []);




  React.useEffect(() => {

    getPartner(airportId)
  }, [airportId]);

  const getData = async (page: number, search: string, date: string, filter: string, airport_id: string, partner_id: string) => {
    setIsLoading(true); // Set loading state before API call

    try {


      const resList = await getAdminReservations({ page, search, date, filter, airport_id, partner_id });
      // @ts-ignore
      if (resList?.error) {
        setReservationList([]);
        setCurrentPage(1);
        setTotalPages(1);
      } else {
        const tPages = Math.ceil(Number(resList?.total) / 10);

        setTotalPages(tPages == 0 ? 1 : tPages);
        setCurrentPage(resList.current_page);
        setReservationList(resList?.data);
      }


    } catch (error) {
      // console.error("Error fetching reservations:", error?.message);
      setCurrentPage(1);
      setTotalPages(1);
      setReservationList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Set a timeout to update the debouncedSearchText after 500ms
    const handler = setTimeout(() => {
      setDebouncedSearchText(search);
    }, 500);

    // Clear the timeout if searchText changes (i.e., the user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  React.useEffect(() => {
    getData(currentPage, search, date, filter, airportId, partnerId);
  }, [currentPage, date, filter, airportId, partnerId, debouncedSearchText]);








  return (
    <>
      <div className="w-full p-[20px] rounded-[20px] bg-white mt-6">
        <div className="flex justify-between items-center flex-wrap gap-3 ">
          <div className="flex flex-col md:gap-0 gap-2 flex-wrap row-gap-3 ">
            <h4 className="text-[20px] text-[#1C1C1C] font-[400] uppercase">
              All Reservations
            </h4>
            <div className="flex items-center gap-8 filter-check-btns">
              <button
                onClick={() => {
                  setCheckIn(!checkIn);
                  setCheckOut(false);
                  setFilter('check_in')
                  getData(currentPage, search, date, 'check_in', airportId, partnerId,);
                }}
              >
                <span className={`${checkIn ? "checked" : ""}`}></span>
                Check in
              </button>
              <button
                onClick={() => {
                  setCheckOut(!checkOut);
                  setCheckIn(false);
                  setFilter('check_out')
                  getData(currentPage, search, date, 'check_out', airportId, partnerId,);
                }}
              >
                <span className={`${checkOut ? "checked" : ""}`}></span>
                Check out
              </button>
              <div className="relative">
                <input
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  className=" absolute left-[0px] top-0 w-[75px] opacity-0 cursor-pointer h-full z-10"
                />


                <button
                  className="flex items-center gap-[7px] py-4 px-2 rounded-[10px] bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800]"
                  type="button"
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z"
                      fill="#2EABE3"
                    />
                  </svg>
                  {date ? moment(date).format('MM/DD/YYYY') : 'Select'}
                </button>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap items-center gap-3 relative">





            {!partnerReservation &&
              <>
                <div className="flex items-center gap-2 bg-[#F2F2F2] p-3 rounded-[10px] ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2076_15039)">
                      <path
                        d="M15.3757 11.6195C15.5431 11.6736 15.6801 11.5741 15.6801 11.3981V10.28C15.6801 10.104 15.5562 9.88639 15.4052 9.79615L9.55495 6.30975C9.40391 6.21983 9.28007 6.00191 9.28007 5.82623V1.92031C9.28007 1.74431 9.22631 1.46687 9.16039 1.30335C9.16039 1.30335 8.81127 0.436793 8.20039 0.340153C8.13507 0.327785 8.06879 0.321146 8.00231 0.320312H7.99911C7.92249 0.320576 7.84607 0.328292 7.77095 0.343353L7.70183 0.358713L7.59687 0.391352C7.08871 0.560952 6.82695 1.29855 6.82695 1.29855C6.76208 1.4997 6.72609 1.70905 6.72007 1.92031V5.82623C6.72007 6.00223 6.59623 6.21983 6.44519 6.31007L0.594948 9.79615C0.443908 9.88639 0.320068 10.104 0.320068 10.28V11.3981C0.320068 11.5741 0.457028 11.6736 0.624388 11.6192L6.41575 9.73919C6.58311 9.68511 6.72007 9.78463 6.72007 9.96063V13.0112C6.72007 13.1872 6.60487 13.4179 6.46407 13.5235L5.69607 14.1002C5.55527 14.2058 5.44007 14.4365 5.44007 14.6125V15.3603C5.44007 15.5363 5.57863 15.6406 5.74759 15.592L7.69159 15.0336C7.86055 14.985 8.13767 14.985 8.30663 15.0336L10.2525 15.592C10.4215 15.6406 10.5601 15.5363 10.5601 15.3603V14.6122C10.5601 14.4362 10.4449 14.2054 10.3041 14.0998L9.53607 13.5232C9.39559 13.4176 9.28007 13.1869 9.28007 13.0109V9.96031C9.28007 9.78431 9.41703 9.68479 9.58439 9.73919L15.3757 11.6195Z"
                        fill="#2EABE3"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2076_15039">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Airports:
                  <select
                    onChange={(e) => {
                      setairPortId(e.target.value)
                    }}
                    className='bg-transparent border-none p-0'>
                    <option value=''>All</option>
                    {airportBox.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
                <div className="block w-[1px] h-[36px] bg-[#E3E3E3]"></div>
                <div className="flex items-center gap-2 bg-[#F2F2F2] p-3 rounded-[10px] ">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2115_13877"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="12"
                      height="16"
                    >
                      <path
                        d="M5.99984 14.6673C5.99984 14.6673 11.3332 10.6673 11.3332 6.33398C11.3332 3.57265 8.9455 1.33398 5.99984 1.33398C3.05417 1.33398 0.666504 3.57265 0.666504 6.33398C0.666504 10.6673 5.99984 14.6673 5.99984 14.6673Z"
                        fill="white"
                        stroke="white"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4.66797V10.0013"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4.66797H7C7.35362 4.66797 7.69276 4.80844 7.94281 5.05849C8.19286 5.30854 8.33333 5.64768 8.33333 6.0013C8.33333 6.35492 8.19286 6.69406 7.94281 6.94411C7.69276 7.19416 7.35362 7.33464 7 7.33464H5V4.66797Z"
                        fill="black"
                        stroke="black"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_2115_13877)">
                      <path d="M-2 0H14V16H-2V0Z" fill="#2EABE3" />
                    </g>
                  </svg>
                  Partners:
                  <select


                    onChange={(event) => {

                      setPartnerId(event.target.value);
                    }}
                    className='bg-transparent border-none p-0'>
                    <option value=''>All</option>
                    {partnerBox.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
                <div className="block w-[1px] h-[36px] bg-[#E3E3E3]"></div>
              </>
            }


            <button
              onClick={async () => {




                const response = await generateReservationCSVReport(
                  partnerId,
                  getStartDateOfCurrentMonth(),
                  'monthly', airportId
                );
                if (response) downloadCSV(response,getStartDateOfCurrentMonth()+"_monthly")
                else {
                  alert('No data found')
                }
                // await downloadPDF(response)
                // alert(JSON.stringify(response))
              }} className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center">
              Monlthy Report
            </button>
            <button
              onClick={async () => {




                const response = await generateReservationCSVReport(
                  partnerId,
                  getStartDateOfCurrentWeek(), 'weekly', airportId
                );
                if (response) downloadCSV(response,  getStartDateOfCurrentWeek()+"_weekly")
                else {
                  alert('No data found')
                }
                // await downloadPDF(response)
                // alert(JSON.stringify(response))
              }} className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center">
              Weekly Report
            </button>


            <div className="block w-[1px] h-[36px] bg-[#E3E3E3]"></div>
            <div className="bg-[#FAFAFA] border rounded-[10px] py-1 px-2 border-[#F0F0F0] flex items-center gap-2">
              <input
                type="search"
                value={search}
                placeholder="Search"
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
                className="shadow-none bg-[transparent] text-[#7A7A7A] text-[12px] font-[400] p-0 border-0 w-[100px]"
              />
              <button type="button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.90906 2C5.93814 2 4.98903 2.28791 4.18174 2.82733C3.37444 3.36674 2.74524 4.13343 2.37368 5.03045C2.00213 5.92746 1.90491 6.91451 2.09433 7.86677C2.28375 8.81904 2.75129 9.69375 3.43783 10.3803C4.12438 11.0668 4.99909 11.5344 5.95135 11.7238C6.90362 11.9132 7.89067 11.816 8.78768 11.4444C9.6847 11.0729 10.4514 10.4437 10.9908 9.63639C11.5302 8.8291 11.8181 7.87998 11.8181 6.90906C11.818 5.60712 11.3008 4.35853 10.3802 3.43792C9.45959 2.51731 8.211 2.00008 6.90906 2Z"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.5703 10.5723L13.9987 14.0007"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-4">
          {isLoading ? (
            <Loader />
          )
            :
            <div className="py-6 overflow-hidden border-t border-b border-[#E3E3E3] mt-6 mb-6 flex flex-col gap-6">
              {reservationList.length > 0 ?

                reservationList.map((item, index) => {

                  return (
                    <RevervationListCard
                      data={item}
                      index={index + 1}
                      partnerId={item?.partner_id}
                      onRefresh={() => {
                        getData(currentPage, search, date, filter, airportId, partnerId);
                      }}
                    />
                  );
                })

                : <p className="text-center text-[#2EABE3] md:text-[20px] text-[16px]">No Reservations Found</p>}
            </div>
          }
        </div>


        <div className="mx-auto text-center w-fit">
          <Pagination totalPages={totalPages} currentPage={currentPage}
            onPage={(page) => {
              setCurrentPage(page)
            }}
            onNext={() => {
              setCurrentPage(currentPage + 1)
            }}
            onPrev={() => {
              setCurrentPage(currentPage - 1)
            }} />
        </div>
      </div>
    </>
  );
};

export default AllReservations;
