import React, { useEffect } from 'react'
import ParkingTypeBox from './ParkingTypeBox'
import OptionBox from './OptionBox'
import { Partner, PartnerOptions } from '../models/types'
import { OPTIIONS_KEYS } from '../models/OptionsValues'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";


type ParkingTypesProps = {
    data: PartnerOptions
    onUpdate: (option: string, value: boolean) => void
}


const TotalReserves = (props: ParkingTypesProps) => {
    const { data, onUpdate } = props
    const schema = yup
        .object({
            booked: yup.string().required(),
            // book_time: yup.string().required(),
        })


    const {
        register,
        handleSubmit,
        setError,
        setValue,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (formData) => {
        onUpdate('booked', formData.booked)
    };

    useEffect(() => {
        setValue('booked', data?.booked)

    }, [data])

    return (
        <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
            <p className='text-[#1C1C1C] text-[12px] font-[600]'>Marketing Text for lots </p>
            <div className=" bg-[white] p-2 rounded-[10px] w-full">

                <div className="flex gap-3 mb-3">
                    <div className="popup-field-box">

                        <input {...register("booked")} type="text" />
                        <p className="text-red-500 text-xs italic">
                            {errors.booked?.message}
                        </p>
                    </div>
                    <button type='submit' className='bg-[#2EABE3] w-full max-w-[150px] py-3 rounded-[10px] text-center text-[white] text-[16px] font-[600]'>Add</button>

                    {/* <div className="popup-field-box">
                        <label>Booked Time</label>
                        <input {...register("book_time")} type="text" />
                        <p className="text-red-500 text-xs italic">
                            {errors.book_time?.message}
                        </p>
                    </div> */}
                </div>


            </div>
        </form>
    )
}

export default TotalReserves
