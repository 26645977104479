import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { GlobalContext } from "../globalContext";
import { useCallback } from "react";
import ProfileSettings from '../admin-components/ProfileSettings';
import ManageAccess from '../admin-components/ManageAccess';

const Profile = () => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [access, setAccess] = useState(false)
    const [settings, setsettings] = useState(true)


    React.useEffect(() => {

        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "profile",
            },
        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Profile",
            },
        });
    }, []);




    return (
        <>
            <PageTitle />

            <div className="mt-4">
                {/* <div className="flex items-center">
                    <button type='button'
                        onClick={() => {
                            setAccess(true)
                            setsettings(false)
                        }}
                        className={`text-[#797979] md:text-[16px] text-[12px] font-[700] py-2 px-5 border-b  uppercase ${access ? 'border-[#2EABE3] text-[#2EABE3]' : 'border-transparent'} `}>Manage access </button>
                    <button type='button'
                        onClick={() => {
                            setsettings(true)
                            setAccess(false)
                        }}
                        className={`text-[#797979] md:text-[16px] text-[12px] font-[700] py-2 px-5 border-b  uppercase ${settings ? 'border-[#2EABE3] text-[#2EABE3]' : 'border-transparent'} `}>Profile setting </button>
                </div> */}
                {/* {settings &&
                    <ProfileSettings />
                }
                {access &&
                <ManageAccess/>
            } */}

<ProfileSettings partnerProfile={true} />
            </div>

        </>
    )
}

export default Profile
