import React from 'react'

const Loader = () => {
    return (
        <div className="flex my-20 justify-center fader">
            <div className="wrapper dot-loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        </div>
    )
}

export default Loader
