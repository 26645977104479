import React from 'react'
import Spinner from '../components/Spinner'

const ReservRevenuBox = ({ Count, BoxTag, Symbol, Ratio, isCanceled }) => {
  return (
    <>
    
        <div className="flex justify-between items-center bg-[#F3F3F3] rounded-[10px] p-[6px]">
          <h3 className='md:text-[25px] text-[24px] font-[800] text-[#1C1C1C]'>{Symbol ? <span className='text-[20px]'>$</span> : ''} {Symbol ? Number(Count).toFixed(2) : Count}</h3>
          {!isCanceled ?
            <span className='md:text-[16px] text-[12px] font-[400] text-[white] bg-[#2EABE3] rounded-[10px] py-[6px] px-[8px]'>{BoxTag}</span>
            :
            <span className='md:text-[16px] text-[12px] font-[400] text-[#ED1A75] bg-[#FEE9F2] rounded-[10px] py-[6px] px-[8px]'>{BoxTag}</span>
          }
        </div>
 
    </>
  )
}

export default ReservRevenuBox
