
import React, { useEffect, useState } from 'react'

import { GlobalContext, showToast } from "../globalContext";
import EditPartnerModal from './EditPartnerModal';
import { useCallback } from "react";
import { updatePartnerValues, updatePartnerstatus } from '../service/AdminApi/partnerApi';
import { Partner } from '../models/types';
import Spinner from '../components/Spinner';

type SinglePriceViewProps = {
    partner: Partner
    title: string
    itemKey: string
    symbol?: 'currency' | 'percent'

}

const SinglePriceView = (props: SinglePriceViewProps) => {
    const { partner, itemKey, title, symbol } = props
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [editMode, setEditMode] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [itemValue, setitemValue] = useState("0")
    useEffect(() => {
        if (partner) {
            setitemValue(partner[itemKey] ?? '0');
        }
    }, [partner])

    const updateComission = (partner: Partner, value: string) => {
        let oldValue = partner[itemKey];
        let shouldUpdate = true
        if (oldValue) {
            if (oldValue == value) {
                shouldUpdate = false
            }
        }
        if (shouldUpdate) {
            setIsloading(true)
            partner[itemKey] = value
            updatePartnerValues(partner.id, { ...partner, }, (success, resp) => {
                if (success) {
                    showToast(globalDispatch, "Updated");
                } else {
                    alert('something went wrong!')
                }
                setIsloading(false)
            });
        }

    }
    const handleBlur = () => {
        setEditMode(false)
        updateComission(partner, itemValue);
    };
    const handleChange = (e) => {
        setitemValue(e.target.value);
    };


    return (
        <>
            <div className="w-fit sm:min-w-max p-2 rounded-[10px] flex items-center gap-2 border border-[#E3E3E3]">
                <p className='wi-fit text-[#1C1C1C] md:text-[20px] md:text-[16px] sm:text-[12px] text-[10px] font-[600] flex items-center gap-2'>
                    {title}:
                    {!editMode && <b>  {symbol == 'currency' && '$'}{itemValue ?? "N/A"}{symbol == 'percent' && '%'} </b>}
                    {editMode && <input
                        type='number'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={itemValue} className='w-[50px] p-0 ' />}
                </p>
                {isloading && <Spinner />}
                {!isloading && <button type='button' onClick={() => {
                    setEditMode(true)
                }} >
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="22" height="22" rx="3" fill="#F3F3F3" />
                        <path d="M6 16.5V14.1389L13.3333 6.81944C13.4444 6.71759 13.5672 6.63889 13.7017 6.58333C13.8361 6.52778 13.9772 6.5 14.125 6.5C14.2731 6.5 14.4167 6.52778 14.5556 6.58333C14.6944 6.63889 14.8148 6.72222 14.9167 6.83333L15.6806 7.61111C15.7917 7.71296 15.8728 7.83333 15.9239 7.97222C15.975 8.11111 16.0004 8.25 16 8.38889C16 8.53704 15.9746 8.67833 15.9239 8.81278C15.8731 8.94722 15.792 9.06981 15.6806 9.18056L8.36111 16.5H6ZM14.1111 9.16667L14.8889 8.38889L14.1111 7.61111L13.3333 8.38889L14.1111 9.16667Z" fill="#2EABE3" />
                    </svg>
                </button>}
            </div>
        </>
    )
}

export default SinglePriceView
