import React, { useEffect, useState } from 'react';
import DynamicInfoModal from './DynamicInfoModal'; // Adjust the import path as needed
import { updatePartnerDynamicInfo } from '../service/AdminApi/partnerApi';
import { DynamicInfo, Partner } from '../models/types';
import { GlobalContext, showToast } from '../globalContext';
import DOMPurify from 'dompurify';

type DynamicInfoListProps = {
    data: Partner
}

const DynamicInfoList = (props: DynamicInfoListProps) => {
    const { data } = props;
    const [items, setItems] = useState<DynamicInfo[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null);
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const handleAddItem = (title: string, description: string) => {
        if (editItemIndex !== null) {
            const updatedItems: DynamicInfo[] = items.map((item, index) =>
                index === editItemIndex ? { title, description } : item
            );
            setItems(updatedItems);
            setEditItemIndex(null);
            updateDynamicInfo(updatedItems);
        } else {
            const nList = [...items, { title, description }];
            updateDynamicInfo(nList);
            setItems(nList);
        }
        setIsModalOpen(false);
    };

    const handleEditItem = (index: number) => {
        setEditItemIndex(index);
        setIsModalOpen(true);
    };

    const handleDeleteItem = (index: number) => {
        const updatedItems = items.filter((_, i) => i !== index);
        updateDynamicInfo(updatedItems);
        setItems(updatedItems);
    };

    useEffect(() => {
        if (data != null) {
            if (data?.dynamic_info) {
                const rawInfo = data?.dynamic_info;
                const json = JSON.parse(rawInfo);
                setItems(json);
            }
        }
    }, [data]);

    const updateDynamicInfo = (list: DynamicInfo[]) => {
        updatePartnerDynamicInfo(data.id, { dynamic_info: list }, (success: boolean) => {
            if (success) {
                showToast(globalDispatch, "Information added");
            } else {
                alert('Oops! something went wrong.');
            }
        });
    };

    return (
        <div className="flex flex-col gap-5">
            <button
                onClick={() => setIsModalOpen(true)}
                className="flex items-center gap-2 mt-4 justify-center w-full bg-[#2eabe3] text-[16px] font-[600] text-[white] rounded-[10px] py-2"
            >
                Add new business info
            </button>

            {items.map((item, index) => (
                <div key={index} className="bg-[#F3F3F3] rounded-[10px] p-4 w-full flex justify-between items-start">
                    <div>
                        <h4 className="text-[16px] font-[800] text-[#383838]">{item.title}</h4>
                        <div
                            className="text-[#898989] text-[16px] mt-2"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }}
                        ></div>
                    </div>
                    <div className="flex gap-2">
                        <button
                            onClick={() => handleEditItem(index)}
                            className="text-[#383838] text-[14px] font-bold underline"
                        >
                            Edit
                        </button>
                        <button
                            onClick={() => handleDeleteItem(index)}
                            className="text-red-500 text-[14px] font-bold underline"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ))}

            {isModalOpen && (
                <DynamicInfoModal
                    onClose={() => {
                        setIsModalOpen(false);
                        setEditItemIndex(null);
                    }}
                    onSave={handleAddItem}
                    initialData={editItemIndex !== null ? items[editItemIndex] : null}
                />
            )}
        </div>
    );
};

export default DynamicInfoList;
