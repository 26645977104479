import React from "react";
import { AuthContext } from "../AuthContext";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../globalContext";
import AdminLogo from '../assets/images/admin-logo.png'
import { useNavigate } from "react-router-dom";



const PartnerHeader = () => {


  const { dispatch } = React.useContext(AuthContext);

  const { state } = React.useContext(GlobalContext);

  const navigate = useNavigate();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
    })
    navigate("/login");
    window.location.reload()
  }


  return (
    <>
      <div
        className={`sidebar-holder relative ${state.menuBar ? "open-nav" : ""}`}
      >
        <div className="sticky top-0 h-fit pb-10">
          <div className="w-full p-4 py-8">
            <NavLink to="/admin/dashboard" className="lg:block hidden">
              <img src={AdminLogo} alt=""
                className="md:w-[120px] w-[80px] mx-auto"
              />
            </NavLink>

            <div className="flex gap-3 mobile-ham">
              {/* <span
                onClick={() => {
                  setShowSide(!state.menuBar)
                }}
              ></span> */}
            </div>
          </div>
          <div className="w-full sidebar-list">
            <ul className="flex flex-wrap ">

              <li className="list-none block w-full pt-4">
                <NavLink
                  to="/dashboard"
                  className={`${state.path == "dashboard" ? "" : ""
                    }`}
                >
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1677 6.56962H8.72152C8.60727 6.56962 8.4977 6.61501 8.41691 6.69579C8.33613 6.77658 8.29075 6.88614 8.29075 7.00039V12.1696C8.29075 12.2839 8.33613 12.3934 8.41691 12.4742C8.4977 12.555 8.60727 12.6004 8.72152 12.6004H12.1677C12.2819 12.6004 12.3915 12.555 12.4723 12.4742C12.5531 12.3934 12.5984 12.2839 12.5984 12.1696V7.00039C12.5984 6.88614 12.5531 6.77658 12.4723 6.69579C12.3915 6.61501 12.2819 6.56962 12.1677 6.56962ZM12.1677 1.40039H8.72152C8.60727 1.40039 8.4977 1.44578 8.41691 1.52656C8.33613 1.60734 8.29075 1.71691 8.29075 1.83116V3.56285C8.29075 3.6771 8.33613 3.78667 8.41691 3.86745C8.4977 3.94824 8.60727 3.99362 8.72152 3.99362H12.1677C12.2819 3.99362 12.3915 3.94824 12.4723 3.86745C12.5531 3.78667 12.5984 3.6771 12.5984 3.56285V1.83116C12.5984 1.71691 12.5531 1.60734 12.4723 1.52656C12.3915 1.44578 12.2819 1.40039 12.1677 1.40039ZM5.27536 1.40039H1.82921C1.71496 1.40039 1.60539 1.44578 1.52461 1.52656C1.44382 1.60734 1.39844 1.71691 1.39844 1.83116V7.00039C1.39844 7.11464 1.44382 7.22421 1.52461 7.30499C1.60539 7.38578 1.71496 7.43116 1.82921 7.43116H5.27536C5.38961 7.43116 5.49918 7.38578 5.57996 7.30499C5.66075 7.22421 5.70613 7.11464 5.70613 7.00039V1.83116C5.70613 1.71691 5.66075 1.60734 5.57996 1.52656C5.49918 1.44578 5.38961 1.40039 5.27536 1.40039ZM5.27536 10.0072H1.82921C1.71496 10.0072 1.60539 10.0525 1.52461 10.1333C1.44382 10.2141 1.39844 10.3237 1.39844 10.4379V12.1696C1.39844 12.2839 1.44382 12.3934 1.52461 12.4742C1.60539 12.555 1.71496 12.6004 1.82921 12.6004H5.27536C5.38961 12.6004 5.49918 12.555 5.57996 12.4742C5.66075 12.3934 5.70613 12.2839 5.70613 12.1696V10.4379C5.70613 10.3237 5.66075 10.2141 5.57996 10.1333C5.49918 10.0525 5.38961 10.0072 5.27536 10.0072Z" fill="#797979" stroke="#797979" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  Dashboard
                </NavLink>
              </li>

              <li className="list-none block w-full ">
                <NavLink
                  to="/checkin-checkout"
                  className={`${state.path == "" ? "" : ""
                    }`}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6667 12.666H3.33333V5.33268H12.6667M10.6667 0.666016V1.99935H5.33333V0.666016H4V1.99935H3.33333C2.59333 1.99935 2 2.59268 2 3.33268V12.666C2 13.0196 2.14048 13.3588 2.39052 13.6088C2.64057 13.8589 2.97971 13.9993 3.33333 13.9993H12.6667C13.0203 13.9993 13.3594 13.8589 13.6095 13.6088C13.8595 13.3588 14 13.0196 14 12.666V3.33268C14 2.97906 13.8595 2.63992 13.6095 2.38987C13.3594 2.13982 13.0203 1.99935 12.6667 1.99935H12V0.666016M11.3333 7.99935H8V11.3327H11.3333V7.99935Z" fill="#797979" />
                  </svg>

                  Check in/Check out
                </NavLink>
              </li>




              <li className="list-none block w-full no-active">
                <NavLink
               
                  onClick={() =>
                    logout()
                  }
                >
                  Logout
                </NavLink>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnerHeader
