import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { useEffect } from 'react';
import { GlobalContext } from "../globalContext";
import ReservationInfoBox from '../admin-components/ReservationInfoBox'
import Revenue from '../admin-components/Revenue'
import AddCoupon from '../admin-modals/AddCoupon';
import { useCallback } from "react";
import AllReservations from '../admin-components/AllReservations';
import { getDashboardData } from '../service/AdminApi/AdminApi'
import AllReservationReports from '../admin-components/AllReservationReports';
import PartnersOverView from '../admin-components/PartnersOverView';
import AllPartners from '../admin-components/AllPartners';


const Partners = () => {




    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    React.useEffect(() => {
        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "partners",
            },
        });

        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },

        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Partners",
            },
        });
    }, []);




    return (
        <>
            <PageTitle />

            <div className="w-full ">
                <PartnersOverView today='2' weekly='2' monthy='4' sale='4' canceled="8" isCanceled={true} />
            </div>
            <AllPartners/>




        </>
    )
}

export default Partners
