import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addRating } from '../service/AdminApi/AdminApi';
import { GlobalContext, showToast } from "../globalContext";
import * as yup from "yup";


const AddRating = ({closeModal,getData, partnerData}) => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);


    const schema = yup
        .object({
            username: yup.string().required(),
            message: yup.string().required(),
            stars: yup.number().required(),
            date: yup.string().required(),
            partner_id: yup.string().required(),
        })
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        addRating(data, (success, resp) => {
            if (success) {
        
              getData(partnerData.id)
              closeModal()
              showToast(globalDispatch, "Review Added");  
      
            } else {
              alert('something went wrong!')
            }
      
          });
    };

    useEffect(function () {
        setValue('partner_id', partnerData?.id )
      }, []);
    


    return (

        <div className="modal-holder">
            <div className="modal-outer" onClick={() => { closeModal() }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
                <h4 className='text-[24px] text-[#2B2B2B] font-[400]'>Add Review</h4>


                <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-4">
                        <div className="popup-field-box">
                        <input
                                {...register("partner_id")}
                                type='hidden' />
                            <label>Enter Name</label>
                            <input
                                {...register("username")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">
                                {errors.title?.message}
                            </p>
                        </div>
                        <div className="popup-field-box">
                            <label>Enter Date</label>
                            <input
                                {...register("date")}
                                type='date' />
                            <p className="text-red-500 text-xs italic">
                                {errors.date?.message}
                            </p>
                        </div>

                        <div className="popup-field-box">
                            <label>Review</label>
                            <input type='text'
                                {...register("message")}
                            />
                            <p className="text-red-500 text-xs italic">
                                {errors.title?.message}
                            </p>
                        </div>


                        <div className="popup-field-box">
                            <label>Rating</label>

                            <select
                                {...register("stars")}
                            >
                                <option value='1'>1</option>
                          
                                <option value='2'>2</option>
                              
                                <option value='3'>3</option>
                     
                                <option value='4'>4</option>
                              
                                <option value='5'>5</option>

                            </select>
                            <p className="text-red-500 text-xs italic">
                                {errors.rating?.message}
                            </p>
                        </div>






                        <div className="flex gap-3">
                            <button type='button' onClick={() => { closeModal() }} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Cancel</button>
                            <button type="submit" className='bg-[#2EABE3] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                                Add
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddRating
