import React, { useEffect } from 'react';
import useIdleTimer from '../utils/useIdleTimer';


const AutoLogout = ({ timeout = 5 * 60 * 1000 }) => { // 15 minutes
  const lastActivity = useIdleTimer(timeout);

  useEffect(() => {
    const storedLastActivity = parseInt(localStorage.getItem('lastActivity'), 10) || Date.now();

    const checkInactivity = () => {
      const now = Date.now();
      if (now - storedLastActivity >= timeout) {
        // Clear session and logout user
        localStorage.removeItem('token'); // Adjust according to your session key
        localStorage.removeItem('role'); // Adjust according to your session key
        localStorage.removeItem('userId'); // Adjust according to your session key
        localStorage.removeItem('UserData'); // Adjust according to your session key
        localStorage.removeItem('lastActivity');
        window.location.href = '/login'; // Redirect to login or logout page
      }
    };

    checkInactivity(); // Check immediately in case the page was reopened after inactivity
    const interval = setInterval(checkInactivity, 1000); // Continue checking every second

    return () => clearInterval(interval);
  }, [lastActivity, timeout]);

  return <></>;
};

export default AutoLogout;
