import axios from "axios";
import moment from "moment";

export function isValidDate(currentDate, workingData) {
  if (!moment.isMoment(currentDate)) {
    return false;
  }

  const dayName = currentDate.format("dddd").toLowerCase(); // e.g., "monday"
  const cDateStr = currentDate.format("YYYY-MM-DD");
  const startTime = moment(
    cDateStr + " " + workingData[`${dayName}_start_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const endTime = moment(
    cDateStr + " " + workingData[`${dayName}_end_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const now = moment();
  if (currentDate.isSame(now, "day") && currentDate.isBefore(now)) {
    return false;
  }
  return currentDate.isBetween(startTime, endTime, null, "[]");
}
export function checkHourDIffrence(checkInDate, checkOutDate) {
  if (!moment.isMoment(checkInDate) || !moment.isMoment(checkOutDate)) {
    return false; // Invalid dates
  }

  const difference = moment.duration(checkOutDate.diff(checkInDate));
  const hoursDiff = difference.asHours();

  return hoursDiff >= 1;
}
export const ONLY_DATE_FORMAT = "MM-DD-YYYY";
export const ONLY_TIME_FORMAT = "hh:mm a";
export const DATE_FORMAT = ONLY_DATE_FORMAT + " " + ONLY_TIME_FORMAT;

export const getDateFromString = (strDate) => {
  return moment(strDate, DATE_FORMAT);
};
export function getDatesBetween(startDate, eDate) {
  const dates = [];
  const currentDate = moment(startDate, DATE_FORMAT);
  const endDate = moment(eDate, DATE_FORMAT);

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.clone().toDate()); // Clone to create new objects
    currentDate.add(1, "days");
  }

  return dates;
}

export const downloadPDF = async (url) => {
  // const url = "https://parkndepart.pixelizewebsolutions.com/public/exports/export_1717805826.pdf";
  const corsProxy = "https://cors-anywhere.herokuapp.com/"; // Public CORS proxy

  try {
    const response = await fetch(corsProxy + url, {
      headers: {
        'Origin': 'http://127.0.0.1:3000' // Your local development URL
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'export_1717805826.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export const getStartDateOfCurrentMonth = () => {
  return moment().startOf('month').format('YYYY-MM-DD');
};

export const getStartDateOfCurrentWeek = () => {
  return moment().startOf('isoWeek').format('YYYY-MM-DD');
};

const downloadFileAtURL = (url) => {
  fetch(url).then(res => {
    console.log(res);

    return res.blob()
  }).then(blob => {
    console.log(blob);

    const blobURL = window.URL.createObjectURL(new Blob([blob]))
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = blobURL;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  })

}


export function getDaysBetweenDates(startDate, endDate) {
  // Parse the dates and set the time to midnight
  let shouldParse = false;
  if (startDate.includes("am") || startDate.includes("AM")) {
    shouldParse = true;
  }
  let start = shouldParse
    ? moment(startDate, "YYYY-MM-DD HH:mm:ss").startOf("day")
    : moment(startDate).startOf("day");
    let end = shouldParse
    ? moment(endDate, "YYYY-MM-DD HH:mm:ss").startOf("day")
    : moment(endDate).startOf("day");
  // let end = moment(endDate).startOf("day");
  console.log(start, end);
  // Calculate the difference in days
  const diffDays = end.diff(start, "days");

  return diffDays + 1;
}

export const downloadCSV = async (url:string,name?:string) => {
  const mainUrl = `https://parkingmaniac.com/api/public/api/export/download?path=${url}&filename=${name ?? "file"}`
  window.open(mainUrl, '_blank')
  // const requestOptions = {
  //   method: "GET",
  //   redirect: "follow"
  // };
  
  // fetch("", requestOptions)
  //   .then((response) => response.text())
  //   .then((result) => console.log(result))
  //   .catch((error) => console.error(error));
  // window.open(url, '_blank')
  // window.open(url, '_blank')

  // try {
  //   const proxyUrl = 'https://api.allorigins.win/raw?url=';
  //   const response = await fetch(proxyUrl + encodeURIComponent(url));
  //   if (!response.ok) {
  //     window.open(url, '_blank')
  //     throw new Error('Network response was not ok');
  //   }
  //   const blob = await response.blob();
  //   const downloadUrl = window.URL.createObjectURL(blob);

  //   // Extracting the file name from the URL
  //   const fileName = url.split('/').pop();

  //   const link = document.createElement('a');
  //   link.href = downloadUrl;
  //   link.setAttribute('download', fileName); // Set the extracted file name
  //   document.body.appendChild(link);
  //   link.click();
  //   link.parentNode.removeChild(link);
  //   window.URL.revokeObjectURL(downloadUrl);
  // } catch (error) {
  //   window.open(url, '_blank')
  //   // console.error('Error downloading the CSV:', error);
  // }
};
