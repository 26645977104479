import React, { useReducer } from "react";
import reservationActions from "./actions/ReservationActions";
export const GlobalContext = React.createContext();

let initialState = {
  globalMessage: "",
  isOpen: true,
  path: "",
  openIndex: -1,
  businessInfo: null,
  reservation: {
    airport: null,
    checkin: "",
    checkout: "",
    package: null,
    carDetails: null,
    reservationResponse: { data: null },
    payment: {
      depositAmount: 0,
      discountAmount: 0,
      subtotal: 0,
      tax: 0,
      total: 0,
    },
  },
  notifications: {
    data: [],
    loading: true,
  },
  depositAmount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SNACKBAR":
      return {
        ...state,
        globalMessage: action.payload.message,
      };

    case "SETPATH":
      return {
        ...state,
        path: action.payload.path,
      };
    case "SETPAGENAME":
      return {
        ...state,
        pageName: action.payload.pageName,
      };

    case "MENUBAR":
      return {
        ...state,
        menuBar: action.payload.menuBar,
      };
    case "NOTIFCATIONS":
      return {
        ...state,
        notifications: action.payload,
      };

    case "PAY_TYPE":
      localStorage.setItem(
        "PAY_TYPE",
        JSON.stringify(action.payload.PaymentType)
      );
      return {
        ...state,
        PaymentType: action.payload.PaymentType,
      };
    case "UPDATE_BUSS_INFO":
      return { ...state, businessInfo: action.payload };

    case "USER_NAME":
      localStorage.setItem(
        "USER_NAME",
        JSON.stringify(action.payload.userName)
      );
      return {
        ...state,
        userName: action.payload.userName,
      };
    case "CHECKINTIME":
      localStorage.setItem(
        "check_in_time",
        JSON.stringify(action.payload.checkInTime)
      );
      return {
        ...state,
        checkInTime: action.payload.checkInTime,
      };

    case "UPDATE_RESERVATION":
      localStorage.setItem(
        "update_reservation",
        JSON.stringify({ ...state.reservation, ...action.payload })
      );

      return {
        ...state,
        reservation: { ...state.reservation, ...action.payload },
      };
    case "DEPOSIT_AMOUNT":
      return {
        ...state,
        depositAmount: action.payload,
      };
    case "PACKAGEID":
      localStorage.setItem(
        "package_id",
        JSON.stringify(action.payload.packageId)
      );
      return {
        ...state,
        packageId: action.payload.packageId,
      };

    case "AIRPORT":
      localStorage.setItem(
        "airport_name",
        JSON.stringify(action.payload.airportName)
      );
      return {
        ...state,
        airportName: action.payload.airportName,
      };

    case "CHECKOUTTIME":
      localStorage.setItem(
        "check_out_time",
        JSON.stringify(action.payload.checkOutTime)
      );
      return {
        ...state,
        checkOutTime: action.payload.checkOutTime,
      };
    default:
      return state;
  }
};

///

///
export const showToast = (dispatch, message, timeout = 3000) => {
  dispatch({
    type: "SNACKBAR",
    payload: {
      message,
    },
  });

  setTimeout(() => {
    dispatch({
      type: "SNACKBAR",
      payload: {
        message: "",
      },
    });
  }, timeout);
};

const GlobalProvider = ({ children }) => {
  // const SelectedPaymentType = localStorage.getItem("PAY_TYPE");
  JSON.stringify({ hello: "data" });
  const [state, dispatch] = useReducer(reducer, initialState);
  React.useEffect(() => {
    (async function () {
      try {
        const reservation = localStorage.getItem("update_reservation");

        const reservationData = JSON.parse(reservation);
        dispatch(reservationActions.updateReservation(reservationData));
      } catch (error) {}
    })();
  }, []);

  // if (openTabName) {
  //   dispatch({ type: "OPEN_TAB_NAME", payload: { openName: openTabName } });
  // }
  // if (openIndex) {
  //   dispatch({
  //     type: "OPEN_TAB_INDEX",
  //     payload: { openIndex: openIndex },
  //   });
  // }

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
