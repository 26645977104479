import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { GlobalContext, showToast } from "../globalContext";
import { getAdminAirports } from "../service/AdminApi/AdminApi";
import { editHours } from "../service/AdminApi/AdminApi";
import { createPartner } from "../service/AdminApi/partnerApi";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../view-partner-components/QuillToolbar";


import 'react-quill/dist/quill.snow.css';
import Spinner from "../components/Spinner";

const AddPartnersModal = ({ onCLose, getData }) => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  // const [value, setValue] = useState("");
  const [airportBox, setAirportBox] = useState([]);
  const [apiError, setApiError] = useState("");
  const [emailNote, setEmailNote] = useState('');
  const [addPartner, setAddPartner] = useState(false)

  const schema = yup
    .object({
      name: yup.string().required(),
      airport_id: yup.string().required(),
      commission: yup.number().max(100, "Value must not exceed 100"),
      email: yup.string().email().required(),
      contact_email: yup.string().email(),
      phone_number: yup
        .string()
        .required("Phone number is required")
        .min(10, "Phone number must be 10 digits")
        .max(10, "Phone number must be 10 digits"),
      first_name: yup
        .string()
        .required()
        .matches(
          /^[A-Za-z\s]+$/,
          "First name must contain only letters and spaces"
        ),
      last_name: yup
        .string()
        .required()
        .matches(
          /^[A-Za-z\s]+$/,
          "Last name must contain only letters and spaces"
        ),
      address: yup.string().required(),
      map_link: yup.string().url().required(),
      // description: yup.string().required(),
      // information: yup.string().required(),
      // email_msg: yup.string().required(),
      // password: yup.string().min(5, 'Password must be 5 digits minimum')
      // .max(8, 'Password can be 10 digits maximum').required(),
      monday_start_time: yup.string(),
      monday_end_time: yup.string(),
      tuesday_start_time: yup.string(),
      tuesday_end_time: yup.string(),
      wednesday_start_time: yup.string(),
      wednesday_end_time: yup.string(),
      thursday_start_time: yup.string(),
      thursday_end_time: yup.string(),
      friday_start_time: yup.string(),
      friday_end_time: yup.string(),
      saturday_start_time: yup.string(),
      saturday_end_time: yup.string(),
      sunday_start_time: yup.string(),
      sunday_end_time: yup.string(),
    })

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getAirport = async () => {
    const airportData = await getAdminAirports();
    setTimeout(() => {
      setAirportBox(airportData.data);
    }, 200);
  };

  const set24Hours = () => {

    setValue("monday_start_time", '00:00');
    setValue("monday_end_time", '23:59');
    setValue("tuesday_start_time", '00:00');
    setValue("tuesday_end_time", '23:59');
    setValue("wednesday_start_time", '00:00');
    setValue("wednesday_end_time", '23:59');
    setValue("thursday_start_time", '00:00');
    setValue("thursday_end_time", '23:59');
    setValue("friday_start_time", '00:00');
    setValue("friday_end_time", '23:59');
    setValue('saturday_start_time', '00:00')
    setValue('saturday_end_time', '23:59')
    setValue('sunday_start_time', '00:00')
    setValue('sunday_end_time', '23:59')
  }

  const onSubmit = async (data) => {
    setAddPartner(true)
    try {
      createPartner(data,emailNote, (success, resp) => {
        if (success) {
          setAddPartner(true)
          showToast(globalDispatch, "Partner Added");
          const partnerId = resp.id;
          editHours(data, partnerId, (success, resp) => {
            if (success) {
              getData();
              onCLose();
              showToast(globalDispatch, "Partner Added");
            } else {
              alert("Unable to update working hours");
            }
          });
        } else {
          setApiError(resp);
          setAddPartner(false)
          // alert(JSON.stringify(resp))
        }
      });
    } catch (error) {
      alert(JSON.stringify(error.message));
      setAddPartner(false)
    }
  };

  React.useEffect(() => {
    getAirport();
  }, []);

  return (
    <div className="modal-holder">
      <div
        className="modal-outer"
        onClick={() => {
          onCLose();
        }}
      ></div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[1100px] w-full mx-auto"
      >
        <div className="flex justify-between items-center">
          <h4 className="md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4">
            Add Partner
          </h4>
          <button
            type="button"
            onClick={() => {
              onCLose();
            }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM20.375 20.375C20.2594 20.4909 20.122 20.5828 19.9708 20.6455C19.8196 20.7083 19.6575 20.7406 19.4937 20.7406C19.33 20.7406 19.1679 20.7083 19.0167 20.6455C18.8655 20.5828 18.7281 20.4909 18.6125 20.375L15 16.7625L11.3875 20.375C11.1538 20.6087 10.8368 20.74 10.5062 20.74C10.1757 20.74 9.85872 20.6087 9.625 20.375C9.39128 20.1413 9.25997 19.8243 9.25997 19.4937C9.25997 19.3301 9.29221 19.168 9.35484 19.0168C9.41747 18.8656 9.50927 18.7282 9.625 18.6125L13.2375 15L9.625 11.3875C9.39128 11.1538 9.25997 10.8368 9.25997 10.5062C9.25997 10.1757 9.39128 9.85872 9.625 9.625C9.85872 9.39128 10.1757 9.25997 10.5062 9.25997C10.8368 9.25997 11.1538 9.39128 11.3875 9.625L15 13.2375L18.6125 9.625C18.7282 9.50927 18.8656 9.41747 19.0168 9.35484C19.168 9.29221 19.3301 9.25997 19.4937 9.25997C19.6574 9.25997 19.8195 9.29221 19.9707 9.35484C20.1219 9.41747 20.2593 9.50927 20.375 9.625C20.4907 9.74073 20.5825 9.87812 20.6452 10.0293C20.7078 10.1805 20.74 10.3426 20.74 10.5062C20.74 10.6699 20.7078 10.832 20.6452 10.9832C20.5825 11.1344 20.4907 11.2718 20.375 11.3875L16.7625 15L20.375 18.6125C20.85 19.0875 20.85 19.8875 20.375 20.375Z"
                fill="#B8B6B6"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col gap-4 add-partner-fields-holder">
          <div className="grid md:grid-cols-3 grid-cols-2 gap-4">
            <div className="popup-field-box">
              <label>Parking lot name</label>
              <input
                {...register("name")}
                type="text"
                placeholder="Enter Parking Lot name"
              />
              <p className="text-red-500 text-xs italic">
                {errors.name?.message && "Please enter Your Name"}
              </p>
              {apiError.includes('name') && <p className="text-red-500 text-xs italic">
                {apiError}
              </p>}
            </div>
            <div className="popup-field-box">
              <label>Select Airport</label>
              <select {...register("airport_id")}>
                <option value="">Select</option>
                {airportBox.map((item) => (
                  <option value={item?.id}>
                    {item?.name} - {item?.code}
                  </option>
                ))}
              </select>
              <p className="text-red-500 text-xs italic">
                {errors.airport_id?.message && "Select Airport"}
              </p>
            </div>
            <div className="popup-field-box">
              <label>Commission</label>
              <input
                {...register("commission")}
                type="text"
                placeholder="Commission %"
              />
              <p className="text-red-500 text-xs italic">
                {errors.commission && "Enter only numeric value"}
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
            <div className="popup-field-box">
              <label>Confirmation Email</label>
              <input
                {...register("email")}
                type="email"
                placeholder="Enter Lot Email Address"
              />
              <p className="text-red-500 text-xs italic">
                {errors.email?.message && "   Enter Your Email "}
              </p>
              {apiError.includes('email') && <p className="text-red-500 text-xs italic">
                {apiError}
              </p>}
            </div>


            <div className="popup-field-box">
              <label>Contact Email</label>
              <input
                {...register("contact_email")}
                type="email"
                placeholder="Enter Contact Email Address"
              />
              <p className="text-red-500 text-xs italic">
                {errors.contact_email?.message && "Enter Your Contact Email "}
              </p>

            </div>


            <div className="popup-field-box">
              <label>Phone Number</label>
              <input
                {...register("phone_number")}
                type="text"
                placeholder="Enter Lot Phone Number"
              />
              <p className="text-red-500 text-xs italic">
                {errors.phone_number?.message && " Enter Correct Phone number max 10 digits"}
              </p>
              {apiError.includes('phone') && <p className="text-red-500 text-xs italic">
                {apiError}
              </p>}
            </div>
            {/* <div className="popup-field-box">
                            <label>Set Password for Partners</label>
                            <input
                                {...register("password")}
                                type='text' placeholder='Enter Password' />
                                 <p className="text-red-500 text-xs italic">
                                {errors.password?.message}
                            </p>
                        </div> */}
            <div className="popup-field-box">
              <label>Lot Owner First Name</label>
              <input
                {...register("first_name")}
                type="text"
                placeholder="First Name"
              />
              <p className="text-red-500 text-xs italic">
                {errors.first_name?.message && " Enter Owner Name"}
              </p>
            </div>
            <div className="popup-field-box">
              <label>Lot Owner Last Name</label>
              <input
                {...register("last_name")}
                type="text"
                placeholder="Last Name"
              />
              <p className="text-red-500 text-xs italic">
                {errors.last_name?.message && "Enter Valid Owner last name"}
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="popup-field-box">
              <label> Address</label>
              <input
                {...register("address")}
                type="text"
                placeholder="Enter Lot Address"
              />
              <p className="text-red-500 text-xs italic">
                {errors.address?.message && "Enter Address"}
              </p>
            </div>
            <div className="popup-field-box">
              <label>Google Map Diretion Link</label>
              <input
                {...register("map_link")}
                type="text"
                placeholder="Past Link here"
              />
              <p className="text-red-500 text-xs italic">
                {errors.map_link?.message && "Enter Map Link"}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {/* <div className="popup-field-box">
                            <label> Listing Description</label>
                            <textarea
                                {...register("description")}
                                placeholder='Enter Listing Description'></textarea>
                                  <p className="text-red-500 text-xs italic">
                                {errors.description?.message}
                            </p>
                        </div>
                        <div className="popup-field-box">
                            <label> Important Information</label>
                            <textarea
                                {...register("information")}
                                placeholder='Enter Important Information'></textarea>
                                   <p className="text-red-500 text-xs italic">
                                {errors.information?.message}
                            </p>
                        </div> */}
            <div className="popup-field-box">
              <label>Email Message</label>
              {/* <textarea
                {...register("email_msg")}
                placeholder="Enter Email Message"
              ></textarea> */}

              <EditorToolbar />
              <ReactQuill
                id="emailNote"
                value={emailNote}
                //  onChange={(e) => setEmailNote(e.target.value)}
                onChange={setEmailNote}
                modules={modules}
                formats={formats}
              />
            
              {/* <p className="text-red-500 text-xs italic">
                {errors.email_msg?.message && "Type Email Note"}
              </p> */}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="popup-field-box flex items-center justify-between">
              <label>Operating Hours</label>
              <button
                onClick={() => {
                  set24Hours()
                }}
                className="bg-[#2EABE3] text-[white] rounded-[10px] py-2 px-4" type="button">24/7 Open</button>
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            <div className="hour-box">
              <p>Monday</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.monday_start_time ? "border-red" : ""
                    }    `}
                  {...register("monday_start_time")}
                  type="time"
                />

                <p>-</p>
                <input
                  className={`${errors.monday_end_time ? "border-red" : ""}   `}
                  {...register("monday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>Tuesday</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.tuesday_start_time ? "border-red" : ""
                    }  `}
                  {...register("tuesday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.tuesday_end_time ? "border-red" : ""}  `}
                  {...register("tuesday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>Wednesday</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.wednesday_start_time ? "border-red" : ""
                    }} `}
                  {...register("wednesday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.wednesday_end_time ? "border-red" : ""
                    }  `}
                  {...register("wednesday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>THURSDAY</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.thursday_start_time ? "border-red" : ""
                    }  `}
                  {...register("thursday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.thursday_end_time ? "border-red" : ""
                    }  `}
                  {...register("thursday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>FRIDAY</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.friday_start_time ? "border-red" : ""} `}
                  {...register("friday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.friday_end_time ? "border-red" : ""}  `}
                  {...register("friday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>SATURDAY</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.saturday_start_time ? "border-red" : ""
                    }  `}
                  {...register("saturday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.saturday_end_time ? "border-red" : ""
                    }  `}
                  {...register("saturday_end_time")}
                  type="time"
                />
              </div>
            </div>
            <div className="hour-box">
              <p>SUNDAY</p>
              <div className="flex items-center gap-1">
                <input
                  className={`${errors.sunday_start_time ? "border-red" : ""
                    }  `}
                  {...register("sunday_start_time")}
                  type="time"
                />
                <p>-</p>
                <input
                  className={`${errors.sunday_end_time ? "border-red" : ""}  `}
                  {...register("sunday_end_time")}
                  type="time"
                />
              </div>
            </div>
          </div>
        </div>

        <button
          className={` bg-[#2EABE3] rounded-[10px] flex items-center justify-center  text-center py-3 text-[white] w-full text-[16px] mt-4 ${addPartner?'disabled' : ''} `}
          type="submit"
        >
          {addPartner? <Spinner/>: 'Add Partner'}
          
        </button>
        {/* {apiError != "" && <p style={color}>{apiError}</p>} */}
      </form>
    </div>
  );
};

export default AddPartnersModal;
