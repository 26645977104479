import React from "react";
import { useState } from "react";
import PageTitle from "../admin-components/PageTitle";
import { useEffect } from "react";
import { GlobalContext } from "../globalContext";
import ReservationInfoBox from "../admin-components/ReservationInfoBox";
import AllReservations from "../admin-components/AllReservations";
import AllAirports from "../admin-components/AllAirports";

const Airports = () => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [showReservations, setShowReservations] = useState(true);

  const [showReservationReports, setShowReservationReports] = useState(false);

  React.useEffect(() => {
    globalDispatch({
      type: "SETPATH",
      payload: {
        path: "airports",
      },
    });

    globalDispatch({
      type: "MENUBAR",
      payload: {
        menuBar: false,
      },
    });

    globalDispatch({
      type: "SETPAGENAME",
      payload: {
        pageName: "Airports",
      },
    });
  }, []);
  return (
    <>
      <PageTitle />

      <AllAirports />
    </>
  );
};

export default Airports;
