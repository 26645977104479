import React, { useState } from "react";
import { getAdminAirports } from "../service/AdminApi/AdminApi";
import OrderListingBox from "../OrderListing/OrderListingBox";
import { getAdminPartners, getAllPartnersData, updatePartnerRanks } from "../service/AdminApi/partnerApi";
import { Partner } from "../models/types";

const OrderListingModal = ({ onClose }) => {
  const [airportBox, setAirportBox] = useState([]);
  const [partnerBox, setPartnerBox] = useState([]);
  const [airportId, setSelectedAirport] = useState('')

  const getAirport = async () => {
    const airportData = await getAdminAirports();
    setAirportBox(airportData.data);
  };

  const getAllPartners = async (airportId) => {
    try {
      const partnerData = await getAllPartnersData(airportId);
      console.log(partnerData);
      
      const unRankedPartners = partnerData.data.filter(it=> it.rank=='')
      const rankedPartners = partnerData.data.filter(it=> it.rank!='')
      // alert(JSON.stringify(unRankedPartners))
      // const partners = partnerData.data.map((partner, index) => ({
      //   ...partner,
      //   rank: partner.rank || index + 1,
      // }));

      // Sort partners by rank
      rankedPartners.sort((a, b) => a.rank - b.rank);
      setPartnerBox([...rankedPartners,...unRankedPartners]);
    } catch (error) {
      setPartnerBox([]);
    }
  };

  React.useEffect(() => {
    getAirport();

  }, []);


  React.useEffect(() => {
    if (airportId) getAllPartners(airportId);
  }, [airportId]);


  const moveItem = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= partnerBox.length) return;

    const updatedPartnerBox = [...partnerBox];
    const [movedItem] = updatedPartnerBox.splice(index, 1);
    updatedPartnerBox.splice(newIndex, 0, movedItem);

    setPartnerBox(
      updatedPartnerBox.map((item, idx) => ({
        ...item,
        rank: idx + 1
      }))
    );
  };

  const handleSaveChanges = async () => {
    console.log(partnerBox);
    const partnerIds = partnerBox.map((item: Partner) => {
      return item.id
    }).join(',')
    const partnerRanks = partnerBox.map((item: Partner) => {
      return item.rank
    }).join(',')
    await updatePartnerRanks(partnerIds, partnerRanks); // Update server with new ranks
    onClose();
  };

  return (
    <>
      <div className="modal-holder">
        <div
          className="modal-outer"
          onClick={onClose}
        ></div>
        <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[800px] w-full mx-auto">
          <h4 className="md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4">
            Manage Order Listings
          </h4>

          <div className="popup-field-box">
            <label>Select Airport</label>
            <select
              onChange={(e) => {
                setSelectedAirport(e.target.value)
              }}
            >
              <option value=''>Select</option>
              {airportBox.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
          {partnerBox.length < 1 && <p className="py-10 md:text-[20px] text-[14px]">Please select Air port first</p>}
          {partnerBox.length > 0 && <div className="order-list-bar-holder mt-7">
            {partnerBox.map((item, index) => (
              <OrderListingBox
                key={item.id}
                data={item}
                index={index}
                moveItem={moveItem}
              />
            ))}
          </div>}
          <button
            onClick={handleSaveChanges}
            className="bg-[#2EABE3] rounded-[10px] text-center py-3 text-[white] w-full text-[16px] mt-4"
            type="button"
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderListingModal;
