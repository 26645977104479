import React from 'react';
import LotImg from '../assets/images/placeholder-img.jpg';
import LocationIcon from '../assets/images/location-icon.svg';

type OrderListingBoxProps = {
  data: import('../models/types').Partner,
  index: number,
  moveItem: (index: number, direction: number) => void
};

const OrderListingBox = (props: OrderListingBoxProps) => {
  const { data, index, moveItem } = props;


  let mainImage = LotImg
  if(data.images.length){
      mainImage = data.images[0].path
  }
  return (
    <div className='fader'>
      <div className="flex items-center p-list-holder justify-between gap-10 overflow-x-auto px-2">
        
        <div className="p-list flex items-center gap-3">
          {/* <select className='bg-[#F5F1F1] rounded-[6px] py-3 border-none'>
            <option>Rank #{data.rank}</option>
          </select> */}
          <div className="p-list flex flex-col items-center">
          <button
       
          onClick={() => moveItem(index, -1)} className="mb-2 w-[20px] h-[20px] bg-[#02abf9] text-white text-center flex items-center justify-center rounded-[50px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-arrow-up" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 12a.5.5 0 0 1-.5-.5V4.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 4.707V11.5a.5.5 0 0 1-.5.5z" />
            </svg>
          </button>
          <button
          className='w-[20px] h-[20px] bg-[#02abf9] text-white text-center flex items-center justify-center rounded-[50px]'
           onClick={() => moveItem(index, 1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-arrow-down" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v6.293l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 10.793V4.5A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
          <img className='w-[100px] h-[70px] object-cover' src={mainImage} alt='' />
          <div className="flex flex-col">
            <h4 className='text-[#1C1C1C] text-[20px] font-[600]'>{data.name}</h4>
          </div>
        </div>
        <div className="p-list">
          <span className='w-fit flex items-center gap-2 rounded-[25px] bg-[#FEE9F2] text-[#1C1C1C] text-[12px] font-[400] py-2 px-3'>
            <img src={LocationIcon} alt='' />
            {data.address}
          </span>
        </div>
        <div className="p-list flex justify-center items-center flex-col gap-1 text-center">
          <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{data.deposit ?? "0"}%</span>
          <p className='text-[#7A7A7A] text-[12px]'>commission</p>
        </div>

      </div>
    </div>
  );
};

export default OrderListingBox;
