import React from 'react'
import AmenitiesBox from './AmenitiesBox'
import shuttle from '../assets/images/shuttle-img.png'
import Vallet from '../assets/images/vallet.png'
import Camera from '../assets/images/24-camera.png'
import clock from '../assets/images/clock.png'
import { Partner, PartnerOptions } from '../models/types'
import { OPTIIONS_KEYS } from '../models/OptionsValues'
import OptionBox from './OptionBox'
type AmenitiesProps = {
    data: PartnerOptions
    onUpdate: (option: string, value: boolean) => void
}
const Amenities = (porps: AmenitiesProps) => {
    const { data, onUpdate } = porps


    return (
        <div className='w-full'>
            <p className='text-[#1C1C1C] text-[12px] font-[600]'>Amenities</p>
            <div className="grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4  bg-[white] p-2 rounded-[10px] w-full">
                <OptionBox
                    onUpdate={onUpdate}
                    partner={data}
                    item_key={OPTIIONS_KEYS.amenities_free_shuttle}
                    amenImg={shuttle}
                    optionType='amenities'
                    amenTitle='Free Shuttle'
                />
                <OptionBox
                 onUpdate={onUpdate}
                    partner={data}
                    item_key={OPTIIONS_KEYS.amenities_vallet}
                    amenImg={Vallet}
                    amenTitle='Valet'
                    optionType='amenities'
                />
                <OptionBox
                 onUpdate={onUpdate}
                    partner={data}
                    item_key={OPTIIONS_KEYS.amenities_surviellance247}
                    amenImg={Camera}
                    amenTitle='24/7 Surviellance'
                    optionType='amenities'
                />
                <OptionBox
                 onUpdate={onUpdate}
                    partner={data}
                    item_key={OPTIIONS_KEYS.amenities_open247}
                    amenImg={clock}
                    optionType='amenities'
                    amenTitle='Open 24/7'
                />


            </div>
        </div>
    )
}

export default Amenities
