import axios from "axios";
import { AuthContext } from "../AuthContext";

export const BASE_URL =
  "https://parkingmaniac.com/api/public/api";

export const IMAGE_URL = "https://parkingmaniac.com/api/public";

// const token = localStorage.getItem("token")

// export const api = axios.create({
//   baseURL: BASE_URL,
//   timeout: 10000,
//   headers: { Accept: "application/json", "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },

// });

class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(endpoint) {
    let token = localStorage.getItem("token");
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return this.handleResponse(response);
  }
  async put(endpoint) {
    let token = localStorage.getItem("token");

    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return this.handleResponse(response);
  }

  async post(endpoint, data) {
    let token = localStorage.getItem("token");
    // alert('apijs' + token)
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    return this.handleResponse(response);
  }

  // Add other methods like put(), delete(), etc. as needed

  async handleResponse(response) {
    if (response.ok) {
      const data = await response.json();
      return { data };
    } else {
      // throw new Error('Something went wrong!');
      return { data: undefined }
      // return { status: response.status, statusText: response.statusText };
    }
  }
}
export const api = new Api(BASE_URL);
