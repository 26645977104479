import React, { useState } from 'react'
import ReviewsSec from '../view-partner-components/ReviewsSec'
import AddRating from '../admin-modals/AddRating'
import { useCallback } from "react";
import { getAdminRating } from '../service/AdminApi/AdminApi';
import { GlobalContext, showToast } from "../globalContext";
import ReviewBox from './ReviewBox';
import Loader from '../admin-components/Loader';

const PartnersReviewSec = ({ partnerData }) => {



    const [showReviewContent, setShowReviewContent] = useState(true)
    const [AddReviewModal, setAddReviewModal] = useState(false)
    const onCloseRating = useCallback(() => {
        setAddReviewModal(false);
    }, [AddReviewModal]);



    const [showPackagesSec, setShowPackagesSec] = useState(true)

    const [AddPackageModal, setAddPackageModal] = useState(false)

    const onCloseAddPackage = useCallback(() => {
        setAddPackageModal(false);
    }, [AddPackageModal]);



    const { dispatch: globalDispatch } = React.useContext(GlobalContext);


    const [isLoading, setIsLoading] = useState(true)

    const [rateBox, setRateBox] = useState([])




    const getData = async (partnerId) => {

        const ratingData = await getAdminRating(partnerId);
        setTimeout(() => {
            setRateBox(ratingData?.data);
            setIsLoading(false)
        }, 200);

    };



    React.useEffect(() => {
        getData(partnerData?.id)
    }, []);


    return (
        <>

            {AddReviewModal &&
                <AddRating getData={getData} partnerData={partnerData} closeModal={onCloseRating} />
            }
            <div className="w-full bg-[white] rounded-[10px] p-4">
                <div className="flex justify-between items-center">
                    <h3 className='text-[#7A7A7A] text-[20px] uppercase'>Reviews</h3>
                    <div className="flex gap-4 items-center">


                        <button
                            onClick={() => {
                                setAddReviewModal(true)
                            }}
                            type='button'
                            className='bg-[#1C1C1C] rounded-[10px] py-3 h-fit px-3 text-[white]'
                        >Add New</button>

                        <div className={`sec-collapse  ${showReviewContent && 'down'} `}
                            onClick={() => {
                                setShowReviewContent(!showReviewContent)
                            }}
                        >
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2083_15931)">
                                    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="#F2F2F2" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8837 7.1157C10.6494 6.88162 10.3317 6.75013 10.0004 6.75013C9.66915 6.75013 9.35145 6.88162 9.11707 7.1157L4.40207 11.829C4.16769 12.0635 4.03605 12.3815 4.03613 12.7131C4.03621 13.0446 4.16799 13.3626 4.40249 13.597C4.63698 13.8313 4.95498 13.963 5.28653 13.9629C5.61808 13.9628 5.93602 13.831 6.17041 13.5965L10.0004 9.76653L13.8304 13.5965C14.0661 13.8243 14.3817 13.9505 14.7095 13.9478C15.0372 13.9451 15.3508 13.8138 15.5827 13.5821C15.8146 13.3505 15.9462 13.037 15.9492 12.7093C15.9522 12.3815 15.8263 12.0657 15.5987 11.8299L10.8846 7.11487L10.8837 7.1157Z" fill="#2EABE3" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2083_15931">
                                        <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                {showReviewContent &&
                    <div className="mt-3">
                        {/* <ReviewsSec partnerData={partnerData} /> */}
                        {isLoading ?
                            <Loader/>
                            :
                            <>
                                {rateBox &&
                                    <div className="mt-3">
                                        <div className="grid md:grid-cols-2 lg:grid-cols-2  grid-cols-1 gap-5">
                                            {rateBox.map((item) => (
                                                <>
                                                    <ReviewBox
                                                        id={item.id}
                                                        getData={getData}
                                                        name={item.username}
                                                        message={item.message}
                                                        stars={item.stars}
                                                        date={item.date}
                                                        partnerData={partnerData}
                                                    />
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </>
                        }

                    </div>
                }
            </div>
        </>
    )
}

export default PartnersReviewSec
