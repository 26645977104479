import React from 'react'
import { useState } from 'react';
import { useCallback } from "react";

import LocationIcon from '../assets/images/location-icon.svg'
import { NavLink } from 'react-router-dom'

import { GlobalContext, showToast } from "../globalContext";
import DeleteModal from './DeleteModal';
import { updatePartnerstatus, deletePartner } from '../service/AdminApi/partnerApi';
import PlaceHolder from '../assets/images/placeholder-img.jpg'
import { Partner } from '../models/types';


type PartnersListProps = {
  data: Partner
  onRefresh: () => void
  index:number

}

const PartnersList = ({index, data, onRefresh }: PartnersListProps) => {


  const [showCancel, setShowCancel] = useState(false)

  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [deleteModal, setDeleteModal] = useState(false)

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModal(false);
  }, [deleteModal]);

  const updateStatus = (status, id) => {

    const statusParams = { status, id }
    updatePartnerstatus(statusParams, (success, resp) => {
      if (success) {
        onRefresh && onRefresh()
        showToast(globalDispatch, "Updated");
      } else {
        alert('something went wrong!')
      }
    });
  }



  const onDelete = () => {
    deletePartner(data?.id, (success, resp) => {
      if (success) {
        // getData()
        onRefresh()
        showToast(globalDispatch, "Deleted");
      } else {
        alert('something went wrong!')
      }
    });
  }
  let mainImage = PlaceHolder
  if (data?.images?.length) {
    mainImage = data?.images[0]?.path
  }
  return (
    <>
      {deleteModal &&
        <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onDelete} />
      }
      <div className="relative fader ">
        <div className="flex items-center p-list-holder justify-between gap-10 overflow-x-auto partner-table-grid">
          <div className="p-list flex items-center gap-3">
            <p className='min-w-[30px]'>{index}</p>
            <NavLink to={`/admin/view_partner/${data?.id}`}>
              <img src={mainImage} alt='' className='w-[100px] h-[70px] object-cover rounded-[10px]' />
            </NavLink>
            <NavLink to={`/admin/view_partner/${data?.id}`} className="flex flex-col">
              <h4 className='text-[#1C1C1C] text-[20px] min-w-[100px] font-[600]'>{data?.name}</h4>
            </NavLink>
          </div>
          <div className="p-list ">
            <span className='w-fit flex items-center gap-2 rounded-[25px] bg-[#FEE9F2] text-[##1C1C1C text-[12px] font-[400] py-2 px-3'>
              <img src={LocationIcon} alt='' />
              {data.address}
            </span>
          </div>
          <div className="p-list flex justify-center items-center flex-col gap-1 text-center">
            <span className='bg-[#2eaae325] min-w-[34px] w-fit py-1 px-2 h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{data.reservations_count?data.reservations_count: '0'}</span>
            <p className='text-[#7A7A7A] text-[12px]' > Reservations</p>
          </div>
          <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{data.commission ? data.commission : '0'}%</span>
            <p className='text-[#7A7A7A] text-[12px]'>Comission</p>
          </div>
          <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${data.total_revenue?data.total_revenue : '0'}</span>
            <p className='text-[#7A7A7A] text-[12px]'>Revenue</p>
          </div>
          <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{data.cancel_reservations_count? data.cancel_reservations_count : '0'}</span>
            <p className='text-[#7A7A7A] text-[12px]'>Cancellations</p>
          </div>
          <div className="p-list">
            <label class="inline-flex items-center cursor-pointer">
              <input
                onChange={() => {
                  updateStatus((data.active == '1' ? '0' : '1'), data.id)
                }}
                type="checkbox" checked={data.active == '1' ? true : false} value="" class="sr-only peer" />
              <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#2eaae31f] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2EABE3]"></div>
            </label>
          </div>
          <button type='button' className='bg-[#F2F2F2] w-[24px] h-[24px] min-w-[24px] rounded-[5px] flex items-center justify-center'
            onClick={() => {
              setShowCancel(!showCancel)
            }}
          >
            <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.8339 1.91649C2.83396 2.10257 2.79737 2.28684 2.72621 2.45877C2.65506 2.63071 2.55074 2.78694 2.4192 2.91856C2.28767 3.05018 2.1315 3.1546 1.95961 3.22586C1.78772 3.29713 1.60348 3.33384 1.4174 3.3339C1.23133 3.33396 1.04706 3.29737 0.875126 3.22621C0.703191 3.15506 0.546954 3.05074 0.415336 2.9192C0.283718 2.78767 0.179297 2.6315 0.108033 2.45961C0.0367699 2.28772 5.98432e-05 2.10348 7.32043e-08 1.9174C-0.000120638 1.54161 0.149049 1.18115 0.414693 0.915337C0.680337 0.649522 1.04069 0.500121 1.41649 0.5C1.79229 0.499879 2.15275 0.649049 2.41856 0.914693C2.68437 1.18034 2.83378 1.5407 2.8339 1.91649Z" fill="#7A7A7A" />
              <path d="M1.41649 8.19627C2.1988 8.19627 2.83299 7.56208 2.83299 6.77978C2.83299 5.99747 2.1988 5.36328 1.41649 5.36328C0.634185 5.36328 0 5.99747 0 6.77978C0 7.56208 0.634185 8.19627 1.41649 8.19627Z" fill="#7A7A7A" />
              <path d="M1.41649 13.0595C2.1988 13.0595 2.83299 12.4254 2.83299 11.6431C2.83299 10.8607 2.1988 10.2266 1.41649 10.2266C0.634185 10.2266 0 10.8607 0 11.6431C0 12.4254 0.634185 13.0595 1.41649 13.0595Z" fill="#7A7A7A" />
            </svg>
          </button>
        </div>
        {showCancel &&
          <div className="reservation-actions z-[999999]">

            <NavLink to={`/admin/view_partner/${data.id}`}
              className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[#3e3e3e] text-[white] hover:bg-[#2EABE3] py-2 w-[190px] text-center text-[16px] font-[500]`} type='button'>
              Edit Partner</NavLink>
            <button
              onClick={() => {
                setDeleteModal(true)
              }}
              className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[#3e3e3e] text-[white] hover:bg-[red] py-2 w-[190px] text-center text-[16px] font-[500]`} type='button'>
              Delete</button>
          </div>
        }
        {showCancel &&
          <div
            onClick={() => {
              setShowCancel(false)
            }}
            className=" absolute left-0 z-[1] top-0 w-full  h-[100vh]"></div>
        }
      </div>

    </>
  )
}

export default PartnersList
