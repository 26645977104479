import React from "react";
import { NavLink } from "react-router-dom";
// import NotificationBox from "./NotificationBox";
import { useState } from "react";
import { GlobalContext } from "../globalContext";
import { AuthContext } from "../AuthContext";
import { IMAGE_URL } from "../service/api";
import AvatarImg from '../assets/images/avatar.png'
import AdminLogo from "../assets/images/logo.svg";
import AdminLogo2 from "../assets/images/admin-logo.png";
// import { GetNotifications } from "../service/AdminApi/AdminApi";

const TopHeader = () => {
  const { user, notifications } = React.useContext(AuthContext).state;
  // const {data:allNotif,loading} = notifications
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [showSide, setShowSide] = useState(false);

  // const [showNoti, setShowNoti] = useState(false);
  // const [bubble, showBubble] = useState(false);
  // const refreshNotification = async () => {
  //   const allNotifications = await GetNotifications();
  //   // alert(JSON.stringify(allNotifications))
  //   const hasAnyUnRead = allNotifications.filter((it) => it.is_read == "0")?.length;
  //   showBubble(hasAnyUnRead);
  //   // setIsLoading(false)
  // };


  React.useEffect(() => {
    // refreshNotification();

    globalDispatch({
      type: "MENUBAR",
      payload: {
        menuBar: showSide,
      },
    });
  }, [showSide]);


  return (
    <>
      <div className="flex justify-between items-center  py-6 relative top-head">
        <div className="flex gap-3 mobile-ham">
          <span
            onClick={() => {
              setShowSide(!showSide);
            }}
          ></span>
          {/* <img className="w-[70px] h-[24px] md:block h  idden" src={AdminLogo} /> */}
          <img className="w-[70px] h-[24px]"  src={AdminLogo2} />
        </div>

        <div className="flex gap-6 items-center ml-auto">



          <NavLink to="/profile">
          {user?.avatar?
            <img
              src={IMAGE_URL + "/" + user?.avatar}
              className="md:w-[40px] md:h-[40px] h-[24px] w-[24px] object-cover rounded-full "
              alt=""
            /> :

<img
              src={AvatarImg}
              className="md:w-[40px] md:h-[40px] h-[24px] w-[24px] object-cover rounded-full"
              alt=""
            />
          }


          </NavLink>
        </div>

      </div>
    </>
  );
};

export default TopHeader;
