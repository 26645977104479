import React, { useEffect, useState } from "react";
import Amenities from "./view-partner-components/Amenities";
import IconBadge from "./view-partner-components/IconBadge";
import ParkingTypes from "./view-partner-components/ParkingTypes";
import { Partner, PartnerOptions } from "./models/types";

import { updatePartnerOptions, updatePartnerValues } from "./service/AdminApi/partnerApi";
import { GlobalContext, showToast } from "./globalContext";
import SinglePriceView from "./view-partner-components/SinglePriceView";
import TotalReserves from "./view-partner-components/TotalReserves";
type PartnersMarketingProps = {
  data: Partner
}

const PartnersMarketing = (props: PartnersMarketingProps) => {
  const { data } = props

  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [isloading, setIsloading] = useState(false)
  const [options, setOptions] = useState<PartnerOptions>({})
  const updateOptions = (options: PartnerOptions) => {
    updatePartnerOptions(data.id, { options: { ...options } }, (success, resp) => {
      if (success) {
        showToast(globalDispatch, "Updated");
      } else {
        alert('something went wrong!')
      }
      setIsloading(false)
    });
  }
  const getOptions = (data: Partner): PartnerOptions => {
    try {
      return JSON.parse(data?.options)
    } catch (error) {
      return {}
    }

  }
  useEffect(() => {
    if (data.options != null) {
      try {
        const json = JSON.parse(data?.options)
        console.log({ oldOptopns: json });

        setOptions(json)
      } catch (error) {

      }
    }
  }, [])

  const onOptionsUpdate = (optionkey: string, value: boolean) => {
    const updatedOption = {}
   
    if (optionkey.includes('badges_')) {
      options['badges_near_airport'] = false
      options['badges_free_shuttle'] = false
      options['badges_hotdeal'] = false
      options['badges_limitedSpace'] = false
      options['badges_sale'] = false
      options['badges_high_rating'] = false
    }
    options[optionkey] = value
    console.log(options);
    updateOptions(options)
    setOptions({...options})

  }
  const [showMarketingSec, setShowMarketingSec] = useState(true);
  return (
    <div className="w-full bg-[white] rounded-[10px] p-4">
      <div className="flex justify-between items-center">
        <h3 className="text-[#7A7A7A] text-[20px] uppercase">Marketing</h3>
        <div className="flex gap-4 items-center">
          <div className="flex md:flex-nowrap flex-wrap gap-2 items-center">
            <SinglePriceView partner={data} itemKey='mile' title="Mile" /> 
            {/* <MilesView partner={data} /> */}
          </div>

          <div
            className={`sec-collapse  ${showMarketingSec && "down"} `}
            onClick={() => {
              setShowMarketingSec(!showMarketingSec);
            }}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2083_15931)">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(1 0 0 -1 0 20.5)"
                  fill="#F2F2F2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.8837 7.1157C10.6494 6.88162 10.3317 6.75013 10.0004 6.75013C9.66915 6.75013 9.35145 6.88162 9.11707 7.1157L4.40207 11.829C4.16769 12.0635 4.03605 12.3815 4.03613 12.7131C4.03621 13.0446 4.16799 13.3626 4.40249 13.597C4.63698 13.8313 4.95498 13.963 5.28653 13.9629C5.61808 13.9628 5.93602 13.831 6.17041 13.5965L10.0004 9.76653L13.8304 13.5965C14.0661 13.8243 14.3817 13.9505 14.7095 13.9478C15.0372 13.9451 15.3508 13.8138 15.5827 13.5821C15.8146 13.3505 15.9462 13.037 15.9492 12.7093C15.9522 12.3815 15.8263 12.0657 15.5987 11.8299L10.8846 7.11487L10.8837 7.1157Z"
                  fill="#2EABE3"
                />
              </g>
              <defs>
                <clipPath id="clip0_2083_15931">
                  <rect
                    width="20"
                    height="20"
                    rx="10"
                    transform="matrix(1 0 0 -1 0 20.5)"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      {showMarketingSec && (
        <div className="mt-3 bg-[#F3F3F3] rounded-[10px] p-4 flex flex-col gap-4">
          
          <TotalReserves onUpdate={onOptionsUpdate} data={options ?? {}} />
          <Amenities onUpdate={onOptionsUpdate} data={options?? {}} />
          <IconBadge onUpdate={onOptionsUpdate} data={options ?? {}} />
          <ParkingTypes onUpdate={onOptionsUpdate} data={options ?? {}} />
        </div>
      )}
    </div>
  );
};

export default PartnersMarketing;
