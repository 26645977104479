import React from 'react'

const DeleteModal = ({ closeModal, deleteCard }) => {
    return (
        <div className="modal-holder">
            <div className="modal-outer" onClick={() => { closeModal() }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
                <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400]'>Are you Sure?</h4>


                <div className="flex items-center gap-2 mt-10">
                    <button
                    onClick={() => { closeModal() }}
                    type="button" className='bg-[#b3b3b3] w-full py-4 rounded-[10px] text-center text-[black] text-[16px] font-[600]' >
                        Cancel
                    </button>
                    <button
                    onClick={()=>{
                        deleteCard()
                        closeModal()
                    }}
                    type="button" className='bg-[#e93838] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                        Delete
                    </button>
                </div>

            </div>
        </div>
    )
}

export default DeleteModal
