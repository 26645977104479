import React, { useEffect } from "react";
import { useState } from "react";
import RevervationListCard from "../admin-components/RevervationListCard";
import Pagination from "../admin-components/Pagination";

import ReservationInfoBox from "../admin-components/ReservationInfoBox";
import { useNavigate, useParams } from "react-router-dom";

import {
  generateReservationCSVReport,
  getPartnerReservations,
  getReservationInfo,
} from "../service/AdminApi/resevationApi";
import { getAdminSinglePartner } from "../service/AdminApi/partnerApi";
import { downloadCSV, getStartDateOfCurrentMonth, getStartDateOfCurrentWeek } from "../utils/utils";
import moment from "moment";
import Loader from "../admin-components/Loader";
type PartnerReservationsProps = {
  forDashboard?: boolean
  noOption?: boolean
  loginPartnerId?: string
}
const PartnerReservations = ({ forDashboard, noOption, loginPartnerId }: PartnerReservationsProps) => {
  // alert('PartnerReservations')
  const [reservationList, setReservationList] = useState([]);

  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resBox, setResBox] = useState({});
  const [searchquery, setSearchQuery] = useState("");
  const [date, setDate] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  // const [partnerId, setPartnerId] = useState(null);

  const params = useParams();
  const partnerId = forDashboard ? loginPartnerId : params.id;
  const getData = async (partnerId, currentPage, searchquery, date, filter) => {

    getReservations(partnerId, currentPage, searchquery, date, filter);
    getInfo(partnerId);
    // const partnerResponse = await getAdminSinglePartner(partnerId);
    // setTimeout(() => {
    //   setIsLoading(false);
    //   let partID = partnerResponse?.id;

    //   getReservations(partID, currentPage);
    //   getInfo(partID);
    // }, 200);
  };

  useEffect(() => {
    // Set a timeout to update the debouncedSearchText after 500ms
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchquery);
    }, 500);

    // Clear the timeout if searchText changes (i.e., the user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [searchquery]);
  const getReservations = async (partID, currentPage, query, date, filter) => {
    try {
      setIsLoading(true);
      const response = await getPartnerReservations(partID, currentPage, query, date, filter);
      setReservationList(response.data);


      const tPages = Math.ceil(Number(response?.total) / 10);


      setTotalPages(tPages == 0 ? 1 : tPages);
      setCurrentPage(response.current_page);
      setIsLoading(false);
    } catch (error) {

      setReservationList([]);
      setTotalPages(1);
      setCurrentPage(1);
      setIsLoading(false);
    }
  };

  const getInfo = async (partID) => {
    const resInfoData = await getReservationInfo(partID);
    setResBox(resInfoData);
  };

  React.useEffect(() => {
    getData(partnerId, currentPage, searchquery, date, filter);
  }, [partnerId, currentPage, debouncedSearchText, date, filter]);

  return (
    <>
      <div className="w-full ">
        <ReservationInfoBox
          today={resBox.reservations ?? "0"}
          weekly={resBox.weekly_reservations ?? "0"}
          monthy={resBox.monthly_reservations ?? "0"}
          canceled={resBox.total_cacnel ?? "0"}
          isCanceled={true}
        />
      </div>
      <div className="w-full p-[20px] rounded-[20px] bg-white mt-6">
        <div className="flex justify-between items-center flex-wrap gap-3 ">
          <div className="flex flex-col md:gap-0 gap-2 flex-wrap row-gap-3 ">
            <h4 className="text-[20px] text-[#1C1C1C] font-[400] uppercase">
              All Reservations
            </h4>
            <div className="flex items-center gap-8 filter-check-btns">
              <button
                onClick={() => {
                  setCheckIn(!checkIn);
                  setCheckOut(false);
                  setFilter('check_in')
                  getData(partnerId, currentPage, searchquery, date, 'check_in');
                }}
              >
                <span className={`${checkIn ? "checked" : ""}`}></span>
                Check in
              </button>
              <button
                onClick={() => {
                  setCheckOut(!checkOut);
                  setCheckIn(false);
                  setFilter('check_out')
                  getData(partnerId, currentPage, searchquery, date, 'check_out');
                }}
              >
                <span className={`${checkOut ? "checked" : ""}`}></span>
                Check out
              </button>
              <div className="relative">
                <input
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  className=" absolute left-[0px] top-0 w-[75px] opacity-0 cursor-pointer h-full z-10"
                />


                <button
                  className="flex items-center gap-[7px] py-4 px-2 rounded-[10px] bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800]"
                  type="button"
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z"
                      fill="#2EABE3"
                    />
                  </svg>
                  {date ? moment(date).format('MM/DD/YYYY') : 'Select'}
                </button>
              </div>
            </div>

          </div>
          <div className="flex flex-wrap items-center gap-3 relative">
            {/* <input
              type="date"
              onChange={(e) => {
                setDate(e.target.value);
                // setTimeout(() => {
                //   getData(1, search, e.target.value, filter);
                // }, 300);
              }}
              className=" absolute left-[-70px] top-0 w-[100px] opacity-0 h-full z-10"
            />
            <button
              className="flex items-center gap-[7px] py-4 px-2 rounded-[10px] bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800]"
              type="button"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z"
                  fill="#2EABE3"
                />
              </svg>
              {date ? moment(date).format('MM/DD/YYYY') : 'Select'}
            </button> */}

            <button

              onClick={async () => {
                try {


                  const response = await generateReservationCSVReport(
                    partnerId,
                    getStartDateOfCurrentMonth(),
                    'monthly'
                  );
                  if (response) downloadCSV(response,getStartDateOfCurrentMonth()+'_monthly')
                  else {
                    alert('No data found')
                  }
                } catch (error) {
                  alert('error')
                }
              }}
              className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center"
            >
              Monthly Report
            </button>
            <button
              onClick={async () => {

                const response = await generateReservationCSVReport(
                  partnerId,
                  getStartDateOfCurrentWeek(),
                  'weekly'
                );
                if (response) downloadCSV(response, getStartDateOfCurrentWeek()+"_weekly")
                else {
                  alert('No data found')
                }
              }}
              className="bg-[#1C1C1C] rounded-[10px] py-2 px-4 text-[white]  text-center"
            >
              Weekly Report
            </button>
            <div className="block w-[1px] h-[36px] bg-[#E3E3E3]"></div>
            <div className="bg-[#FAFAFA] border rounded-[10px] py-1 px-2 border-[#F0F0F0] flex items-center gap-2">
              <input
                placeholder="Search"
                type="text"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  // setTimeout(() => {
                  //   getData(1, search, date, filter);
                  // }, 300);
                }}
                className="shadow-none bg-[transparent] text-[#7A7A7A] text-[12px] font-[400] p-0 border-0 w-[100px]"
              />
              <button type="button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.90906 2C5.93814 2 4.98903 2.28791 4.18174 2.82733C3.37444 3.36674 2.74524 4.13343 2.37368 5.03045C2.00213 5.92746 1.90491 6.91451 2.09433 7.86677C2.28375 8.81904 2.75129 9.69375 3.43783 10.3803C4.12438 11.0668 4.99909 11.5344 5.95135 11.7238C6.90362 11.9132 7.89067 11.816 8.78768 11.4444C9.6847 11.0729 10.4514 10.4437 10.9908 9.63639C11.5302 8.8291 11.8181 7.87998 11.8181 6.90906C11.818 5.60712 11.3008 4.35853 10.3802 3.43792C9.45959 2.51731 8.211 2.00008 6.90906 2Z"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.5703 10.5723L13.9987 14.0007"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (

          <Loader />
        ) : (
          <div className="py-6 overflow-hidden border-t border-b border-[#E3E3E3] mt-6 mb-6 flex flex-col gap-6">
            {reservationList.length > 0 ?
              reservationList.map((item) => (
                <>
                  <RevervationListCard
                    noOption={noOption}
                    onRefresh={() => {
                      getData(partnerId, currentPage, searchquery, date, filter);
                    }}
                    partnerId={item?.partner_id}
                    data={item} />
                </>
              ))
              :
              <p className="text-center text-[#2EABE3] md:text-[20px] text-[16px]">No Reservations Found</p>
            }
          </div>
        )}
        <div className="mx-auto text-center w-fit">
          <Pagination totalPages={totalPages} currentPage={currentPage}
            onPage={(page) => {
              setCurrentPage(page)
            }}
            onNext={() => {
              setCurrentPage(currentPage + 1)
            }}
            onPrev={() => {
              setCurrentPage(currentPage - 1)
            }} />
        </div>
      </div>


    </>
  );
};

export default PartnerReservations;
