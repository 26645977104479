
import React, { useState } from 'react'

import { GlobalContext, showToast } from "../globalContext";
import EditPartnerModal from './EditPartnerModal';
import { useCallback } from "react";
import { updatePartnerstatus } from '../service/AdminApi/partnerApi';
import { partnerOverView } from '../service/AdminApi/resevationApi';


import SinglePriceView from './SinglePriceView';
import Spinner from '../components/Spinner';
import DOMPurify from 'dompurify';



const AboutPartner = ({ partnerData, getData }) => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [editPartnerModal, setEditPartnerModal] = useState(false)
    const [resInfo, setResInfo] = useState({})
    const [readMore, setReadMore] = useState(false)
    const onCloseEditPartnerModal = useCallback(() => {
        setEditPartnerModal(false);
    }, [editPartnerModal]);

    const updateStatus = (status) => {
        const id = partnerData?.id
        const statusParams = { status, id }
        updatePartnerstatus(statusParams, (success, resp) => {
            if (success) {
                showToast(globalDispatch, "Updated");
                getData()
            } else {
                alert('something went wrong!')
            }
        });
    }

    const getOverview = async () => {
        const partner_id = partnerData?.id
        const response = await partnerOverView(partner_id)
        setResInfo(response)
    }

    React.useEffect(() => {

        getOverview()
    }, []);


    const sanitizedHTML = DOMPurify.sanitize(partnerData?.email_msg);


    return (
        <>

            {editPartnerModal &&

                <EditPartnerModal getData={getData} partnerData={partnerData} onCLose={onCloseEditPartnerModal} />

            }
            {readMore &&
                <div className="modal-holder">
                    <div className="modal-outer" onClick={() => { setReadMore(false) }}></div>
                    <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[900px] w-full mx-auto">
                        <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4'>Email Message</h4>
                        <p className='md:text-[20px] text-[14px]'>   <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} /></p>
                    </div>
                </div>
            }
            <div className="w-full bg-[white] rounded-[10px] p-4">
                <div className="lg:flex-row flex-col flex  justify-between gap-1  ">
                    <div className="block md:min-w-max min-w-[100%] w-max">
                        <div className="flex items-center gap-4">
                            <h3 className='text-[#1C1C1C] md:text-[40px] text-[24px] font-[600]'>{partnerData?.name}  </h3>

                            {partnerData?.rank != "" && <span className='text-[#7A7A7A] text-[14px] bg-[#F5F1F1] py-3 px-4 rounded-[10px]'>Rank# {partnerData?.rank}</span>}

                        </div>
                        <div className="flex mg:flex-row flex-col gap-2 mt-2 mb-4">
                            <span className='flex items-center w-full sm:text-[12px] text-[8px] gap-2 py-2 px-4 bg-[#F3FAFE] text-[#2EABE3] rounded-[20px] font-[700]'>
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2090_16433)">
                                        <path d="M17.298 13.571C17.4863 13.6318 17.6404 13.5199 17.6404 13.3219V12.064C17.6404 11.866 17.501 11.6212 17.3311 11.5197L10.7496 7.59749C10.5797 7.49633 10.4404 7.25117 10.4404 7.05353V2.65937C10.4404 2.46137 10.3799 2.14925 10.3057 1.96529C10.3057 1.96529 9.91295 0.990415 9.22571 0.881695C9.15222 0.867781 9.07766 0.860313 9.00287 0.859375H8.99927C8.91307 0.859671 8.82711 0.868352 8.74259 0.885295L8.66483 0.902575L8.54675 0.939295C7.97507 1.13009 7.68059 1.9599 7.68059 1.9599C7.60761 2.18619 7.56713 2.4217 7.56035 2.65937V7.05353C7.56035 7.25153 7.42103 7.49634 7.25111 7.59786L0.669592 11.5197C0.499672 11.6212 0.360352 11.866 0.360352 12.064V13.3219C0.360352 13.5199 0.514432 13.6318 0.702712 13.5706L7.21799 11.4556C7.40627 11.3948 7.56035 11.5067 7.56035 11.7047V15.1366C7.56035 15.3346 7.43075 15.5942 7.27235 15.713L6.40835 16.3617C6.24995 16.4805 6.12035 16.7401 6.12035 16.9381V17.7794C6.12035 17.9774 6.27623 18.0947 6.46631 18.04L8.65331 17.4118C8.84339 17.3571 9.15515 17.3571 9.34523 17.4118L11.5344 18.04C11.7245 18.0947 11.8804 17.9774 11.8804 17.7794V16.9377C11.8804 16.7397 11.7508 16.4801 11.5924 16.3613L10.7284 15.7126C10.5703 15.5938 10.4404 15.3343 10.4404 15.1363V11.7044C10.4404 11.5064 10.5944 11.3944 10.7827 11.4556L17.298 13.571Z" fill="#2EABE3" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2090_16433">
                                            <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                {partnerData?.airport?.name} - ({partnerData?.airport?.code})
                            </span>
                            <span className='flex items-center w-full sm:text-[12px] text-[8px] gap-2 py-2 px-4 bg-[#FEE9F2] text-[black] rounded-[20px] font-[600]'>
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 9.125C8.50272 9.125 8.02581 8.92746 7.67417 8.57582C7.32254 8.22419 7.125 7.74728 7.125 7.25C7.125 6.75272 7.32254 6.27581 7.67417 5.92417C8.02581 5.57254 8.50272 5.375 9 5.375C9.49728 5.375 9.97419 5.57254 10.3258 5.92417C10.6775 6.27581 10.875 6.75272 10.875 7.25C10.875 7.49623 10.8265 7.74005 10.7323 7.96753C10.638 8.19502 10.4999 8.40171 10.3258 8.57582C10.1517 8.74993 9.94502 8.88805 9.71753 8.98227C9.49005 9.0765 9.24623 9.125 9 9.125ZM9 2C7.60761 2 6.27226 2.55312 5.28769 3.53769C4.30312 4.52226 3.75 5.85761 3.75 7.25C3.75 11.1875 9 17 9 17C9 17 14.25 11.1875 14.25 7.25C14.25 5.85761 13.6969 4.52226 12.7123 3.53769C11.7277 2.55312 10.3924 2 9 2Z" fill="#ED1A75" />
                                </svg>

                                {partnerData?.address}
                            </span>
                        </div>
                        <div className="max-w-[400px] w-full rounded-[10px] bg-[#F3FAFE] p-3 mb-4">
                            <h4 className='md:text-[24px] text-[20px] text-[#1C1C1C] font-[500]'>{partnerData?.first_name} {partnerData?.last_name} </h4>
                            <p className='text-[#7A7A7A] text-[14px] font-[500]' ><b>Confirmation Email</b>: {partnerData?.email}</p>
                            {partnerData?.contact_email &&
                                <p className='text-[#7A7A7A] text-[14px] font-[500]' ><b>Contact Email</b>: {partnerData?.contact_email}</p>
                            }
                            <p className='text-[#7A7A7A] text-[14px] font-[500]' ><b>Phone:</b> {partnerData?.phone_number}</p>
                        </div>
                        {/* <CommissionView partner={partnerData}  /> */}
                        <SinglePriceView symbol='percent' partner={partnerData} title='Commission' itemKey='commission' />
                    </div>
                    <div className="block min-w-max lg:w-max lg:mt-0 mt-5">
                        <div className="flex justify-between w-full items-center mb-2">
                            <div className="flex w-full gap-2 ">
                                <p>Lot Active</p>
                                <div className="p-list">
                                    <label class="inline-flex items-center cursor-pointer">
                                        <input
                                            onChange={() => {
                                                updateStatus(partnerData?.active == '1' ? '0' : '1')
                                            }}
                                            checked={partnerData?.active == '1' ? true : false} type="checkbox" value="" class="sr-only peer" />
                                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#2eaae31f] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2EABE3]"></div>
                                    </label>
                                </div>
                            </div>
                            <button type='button' className='flex items-center gap-2'
                                onClick={() => {
                                    setEditPartnerModal(true)
                                }}
                            >
                                Edit
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="22" height="22" rx="3" fill="#F3F3F3" />
                                    <path d="M6 16.5V14.1389L13.3333 6.81944C13.4444 6.71759 13.5672 6.63889 13.7017 6.58333C13.8361 6.52778 13.9772 6.5 14.125 6.5C14.2731 6.5 14.4167 6.52778 14.5556 6.58333C14.6944 6.63889 14.8148 6.72222 14.9167 6.83333L15.6806 7.61111C15.7917 7.71296 15.8728 7.83333 15.9239 7.97222C15.975 8.11111 16.0004 8.25 16 8.38889C16 8.53704 15.9746 8.67833 15.9239 8.81278C15.8731 8.94722 15.792 9.06981 15.6806 9.18056L8.36111 16.5H6ZM14.1111 9.16667L14.8889 8.38889L14.1111 7.61111L13.3333 8.38889L14.1111 9.16667Z" fill="#2EABE3" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-col gap-3">

                            <div className="flex items-center justify-between md:min-w-[350px] min-w-[180px] bg-[#F3F3F3] rounded-[10px] py-2 px-3">
                                <p className='text-[#1C1C1C] text-[20px] font-[800] flex items-center'>${resInfo?.total_revenue ?? <Spinner />}</p>
                                <span className='bg-[#2EABE3] text-[white] py-2 px-2 rounded-[10px]'>Revenue</span>
                            </div>
                            <div className="flex items-center justify-between md:min-w-[350px] min-w-[180px] bg-[#F3F3F3] rounded-[10px] py-2 px-3">
                                <p className='text-[#1C1C1C] text-[20px] font-[800] flex items-center'>{resInfo?.total_reservations ?? <Spinner />}</p>
                                <span className='bg-[#2EABE3] text-[white] py-2 px-2 rounded-[10px]'>Reservations</span>
                            </div>
                            <div className="flex items-center justify-between md:min-w-[350px] min-w-[180px] bg-[#F3F3F3] rounded-[10px] py-2 px-3">
                                <p className='text-[#1C1C1C] text-[20px] font-[800] flex items-center'>{resInfo?.total_cacnelations ?? <Spinner />}</p>
                                <span className='bg-[#FEE9F2] text-[#ED1A75] py-2 px-2 rounded-[10px]'>Cancellations</span>
                            </div>
                            {partnerData.email_msg &&
                                <div className="bg-[#F3F3F3] rounded-[10px] p-4 w-full">
                                    <h4 className="text-[16px] font-[800] text-[#383838] flex items-center justify-between">
                                        Email Note
                                        <button
                                            className='text-[14px] text-[#2EABE3]'
                                            onClick={() => {
                                                setReadMore(true)
                                            }}
                                            type='button'>Read</button>
                                    </h4>
                                    <p className="text-[#898989] text-[16px] mt-2 note-line-clamp">
                                     
                                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutPartner
