import React from 'react'
import IconBadgeBox from './IconBadgeBox'
import { Partner, PartnerOptions } from '../models/types'
import OptionBox from './OptionBox'
import { OPTIIONS_KEYS } from '../models/OptionsValues'
type IconBadgeProps = {
    data: PartnerOptions
    onUpdate: (option: string, value: boolean) => void
}
const IconBadge = (props: IconBadgeProps) => {
    const { data, onUpdate } = props
    return (
        <>
            <div className='w-full'>
                <p className='text-[#1C1C1C] text-[12px] font-[600]'>Icon Badges </p>
                <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-4 sm:grid-cols-2 grid-cols-1  bg-[white] p-2 rounded-[10px] w-full">

                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_near_airport}
                        optionType='badges'
                        icon='near'
                    />

                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_free_shuttle}
                        optionType='badges'
                        icon='free_shuttle'
                    />

                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_hotdeal}
                        optionType='badges'
                        icon='hot_deal'
                    />

                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_limitedSpace}
                        optionType='badges'
                        icon='limited_space'
                    />

                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_sale}
                        optionType='badges'
                        icon='sale'
                    />


                    <OptionBox
                        onUpdate={onUpdate}
                        partner={data}
                        item_key={OPTIIONS_KEYS.badges_high_rating}
                        optionType='badges'
                        icon='rating'
                    />
                </div>
            </div>
        </>


    )
}

export default IconBadge
