import { useEffect, useState } from 'react';

const useIdleTimer = (timeout) => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const resetTimer = () => {
        console.log('resetTimer');
        
      const now = Date.now();
      setLastActivity(now);
      localStorage.setItem('lastActivity', now); // Save to localStorage
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('touchstart', resetTimer);

    return () => {
      // Clean up event listeners
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
    };
  }, []);

  return lastActivity;
};

export default useIdleTimer;
