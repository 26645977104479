import React, { useState } from 'react'
import EditIcon from '../assets/images/review-edit.svg'
import RatingStarsBar from '../search-page-component/RatingStarsBar';
import { useCallback } from "react";
import EditRating from '../admin-modals/EditRating';
import Avatar from '../assets/images/avatar.svg'
import BinIcon from '../assets/images/bin-icon.svg'
import DeleteModal from "../admin-components/DeleteModal";
import { deleteReview } from '../service/AdminApi/AdminApi';
import { GlobalContext, showToast } from "../globalContext";



const ReviewBox = ({ name, date, message, partnerData, getData, stars, id }) => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);


    const [AddReviewModal, setAddReviewModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const onCloseEditRating = useCallback(() => {
        setAddReviewModal(false);
    }, [AddReviewModal]);


    const onCloseDeleteModal = useCallback(() => {
        setDeleteModal(false);
    }, [deleteModal]);

    const onDelete = () => {
        const partnerId = partnerData.id;
        deleteReview(id, (success, resp) => {
            if (success) {
                showToast(globalDispatch, "Deleted");
                getData(partnerId)
            } else {
                //   alert('something went wrong!')
            }
        });

    };

    return (
        <>
            {deleteModal && (
                <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onDelete} />
            )}
            {AddReviewModal &&
                <EditRating id={id} getData={getData} partnerData={partnerData} name={name} date={date} message={message} stars={stars} closeModal={onCloseEditRating} />
            }
            <div className="bg-[#FFFFFF] border border-[#EBEBEB] rounded-[10px] p-4 flex items-center justify-between">
                <div className="flex gap-2 items-center">
                    <img src={Avatar} className='w-[58px] h-[58px] rounded-full border-[4px]' alt='' />
                    <div className="flex flex-col max-w-[65%]">
                        <p className='text-[#2B2B2B] text-[12px] font-[700]'>{name}</p>
                        <p className='text-[12px] mt-2'>{message}</p>
                    </div>
                </div>

                <div className="flex flex-col justify-between items-end gap-3 min-w-max h-full justify-between">
                    <RatingStarsBar rating={stars} />
                    <img onClick={() => {
                        setAddReviewModal(true)
                    }} className='w-[22px]' src={EditIcon} />
                    <img onClick={() => {
                        setDeleteModal(true);
                    }} className='w-[22px]' src={BinIcon} />

                    <p className='text-[#707070] text-[10px]'>{date}</p>
                </div>
            </div>
        </>
    )
}

export default ReviewBox
