import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addCoupon } from "../service/AdminApi/AdminApi";
import { GlobalContext, showToast } from "../globalContext";


const AddCoupon = ({closeModal, getData, isLoading}) => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [isPercentage, setIsPercentage] = useState('1')


    const schema = yup
        .object({
            coupon_title: yup.string().required(),
            coupon_code: yup.string().required(),
            type: yup.string().required(),
            ...(isPercentage === '1' && {
                percentage: yup.number().max(100, 'Value must not exceed 100'),
            }),
            ...(isPercentage === '0' && {
                amount: yup.string(),  // Add validation for amount if needed
            }),
        })
        .required();

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        isLoading(true)
        addCoupon(data, (success, resp) => {
            if (success) {
            //   alert('coupon added successfully')
            //   alert(JSON.stringify(resp));
              getData()
                closeModal()
                showToast(globalDispatch, "Coupon Added");
                isLoading(false)
            } else {
              alert('something went wrong!')
            }
      
          });
    };




    return (
        <div className="modal-holder">
            <div className="modal-outer" onClick={()=>{closeModal()}}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[540px] w-full mx-auto">
                <h4 className='text-[24px] text-[#2B2B2B] font-[400]'>Add New Coupon</h4>


                <form className='mt-8' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col gap-4">
                        <div className="popup-field-box">
                            <label>Enter Title</label>
                            <input
                                {...register("coupon_title")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">
                                {errors.coupon_title?.message}
                            </p>
                        </div>
                        <div className="popup-field-box">
                            <label>Enter Coupon Code</label>
                            <input
                                {...register("coupon_code")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">
                                {errors.coupon_code?.message}
                            </p>
                        </div>
                        <div className="popup-field-box">
                            <label>Select charge type</label>
                            <input type='hidden'
                                {...register("type")}
                                value={isPercentage}
                            />
                            {/* <input type='hidden'
                                {...register("is_percentage")}
                                value={isPercentage}
                            /> */}

                            <div className="percent-field-holder">
                                <select
                                    onChange={(e) => {
                                        setValue("type", e.target.value);
                                        setValue("is_percentage", e.target.value);

                                        setIsPercentage(e.target.value)
                                    }}
                                    value={isPercentage}
                                >
                                    <option value='1'>Percentage</option>
                                    <option value='0'>Flat</option>
                                </select>
                                {isPercentage === '1' ?
                                    <input
                                        {...register("percentage")}
                                        placeholder='0.00%'
                                        type='text' />
                                    :
                                    <></>
                                }
                                {isPercentage === '0' ?
                                    <input
                                        {...register("amount")}
                                        placeholder='$20'
                                        type='text' />
                                    :
                                    <></>
                                }


                            </div>
                            <p className="text-red-500 text-xs italic">
                                {errors.percentage?.message}
                            </p>
                            <p className="text-red-500 text-xs italic">
                                {errors.amount?.message}
                            </p>
                        </div>
                        <p className="text-red-500 text-xs italic">
                            {errors.type?.message}
                        </p>


                        <div className="flex gap-3">
                            <button type='button' onClick={()=>{closeModal()}} className='py-4 rounded-[10px] w-[100px] bg-[#F3F3F3]'>Cancel</button>
                            <button type="submit" className='bg-[#2eabe3] w-full py-4 rounded-[10px] text-center text-[white] text-[16px] font-[600]' >
                                Add
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddCoupon
