import React, { useEffect, useRef, useState } from 'react';
import LotImg from '../assets/images/placeholder-img.jpg';
import { Partner } from '../models/types';
import { updatePartnerProfileImage } from '../service/AdminApi/partnerApi';
import Spinner from '../components/Spinner';

type PartnerLotGalleryProps = {
  data: Partner;
};

const PartnerLotGallery = (props: PartnerLotGalleryProps) => {
  const { data } = props;
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState(data?.images || []);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  const handleUpload = async () => {
    setIsUploading(true);
    const formData = new FormData();
    selectedImages.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    try {
      const response = await fetch('https://parkingmaniac.com/api/public/api/admin/partners/' + data.id + '/upload-images', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust token retrieval as needed
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Upload successful:', responseData["0"]);
        const nImages = responseData["0"];
        setImages((prevImages) => [...prevImages, ...nImages]);
        setSelectedImages([])
      } else {
        console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteImage = async (imageId: number) => {
    try {
      setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
      const response = await fetch(`https://parkingmaniac.com/api/public/api/admin/partners/${imageId}/delete-image`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust token retrieval as needed
        },
      });

      if (response.ok) {
        console.log('Delete successful');
        // setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
      } else {
        console.error('Delete failed:', response.statusText);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="xl:max-w-[200px] overflow-auto min-w-[200px] w-full flex xl:flex-col gap-5 lot-img-holder">
        {images.map((item) => (
          <div key={item.id} className="relative">
            <img
              className="w-full min-w-max rounded-[10px] h-[100px] object-cover"
              src={item.path}
              alt=""
            />
            <button
              onClick={() => handleDeleteImage(item.id)}
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.71 3.21C14.53 3.02 14.28 2.92 14.03 2.92H9.97C9.72 2.92 9.47 3.02 9.29 3.21L8.5 4H5V6H19V4Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        ))}

        {selectedImages.map((image, index) => (
          <img
            key={index}
            className="w-full min-w-max rounded-[10px] h-[100px] object-cover"
            src={URL.createObjectURL(image)}
            alt={`Selected ${index}`}
          />
        ))}
      </div>
      {!isUploading && (
        <button
          onClick={handleButtonClick}
          className="flex items-center gap-2 justify-center w-full bg-[#F3F3F3] rounded-[10px] py-4"
        >
          Upload Images
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.47933 19.8043 5.088 19.413C4.69667 19.0217 4.50067 18.5507 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.3043 19.021 19.913 19.413C19.5217 19.805 19.0507 20.0007 18.5 20H6.5Z"
              fill="#7A7A7A"
            />
          </svg>
        </button>

      )}
      <p className='text-[12px] text-center' >Only JPG and PNG Supported</p>
      <input
        type="file"
        accept="image/*"
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {selectedImages.length > 0 && !isUploading && (
        <button
          onClick={handleUpload}
          className="flex items-center gap-2 justify-center w-full bg-[#F3F3F3] rounded-[10px] py-4 mt-4"
        >
          Submit
        </button>
      )}
      {isUploading && <Spinner />}
    </div>
  );
};

export default PartnerLotGallery;
