import React from "react";
import logo from "../assets/images/logo.svg"

import { useState } from "react";


import { NavLink } from "react-router-dom";


export const PublicHeader = () => {


 const [openNav, setOpenNav] = useState(false) 


  return (
    <>

      <header className="#FFFFFF py-5 ">
        <div className="container-fluid mx-auto lg:px-10 px-5">

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <button onClick={()=>{
                setOpenNav(!openNav)
              }} type="button" className="lg:hidden block nav-btn">
                <svg width="20" height="17" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.333008 0.728027H13.6663V2.39469H0.333008V0.728027ZM0.333008 4.89469H13.6663V6.56136H0.333008V4.89469ZM0.333008 9.06136H13.6663V10.728H0.333008V9.06136Z" fill="black" />
                </svg>

              </button>
              <NavLink className='logo-box' to='/'>
                <img src={logo} alt="" />
              </NavLink>
            </div>
            <div className={`flex items-center gap-10 side-nav  ${openNav? 'show-navigation' : ''} `}>
              <NavLink className="text-[16px] font-[400] text-[#2B2B2B]" to="/edit_reservation">Edit Reservation</NavLink>
              <NavLink className="text-[16px] font-[400] text-[#2B2B2B]" to="/edit_reservation">Cancellation</NavLink>
              <NavLink className="text-[16px] font-[400] text-[#2B2B2B]" to="/edit_reservation">Search Receipt</NavLink>
            </div>
            {/* <div className="flex md:gap-10 gap-3 h-fit items-center">
              <NavLink to="/search" className="text-[#2B2B2B] h-fit md:text-[18px] text-[12px] font-bold" type="button">Log in</NavLink>
              <button className="text-white md:text-[18px] text-[12px] font-bold bg-[#2EABE3] h-fit md:py-3 py-2 md:px-6 px-4" type="button">Sign Up</button>
            </div> */}
          </div>
        </div>
      </header>




    </>
  );
};

export default PublicHeader;
