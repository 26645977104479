import React, { useEffect, useState } from "react";
import Pagination from "./Pagination";
import ReservationReportCard from "./ReservationReportCard";
import AirportListCard from "./AirportListCard";
import { useCallback } from "react";
import AddAirport from "../admin-modals/AddAirport";

import Loader from "./Loader";
import { getAdminAirports } from "../service/AdminApi/AdminApi";
import { Airport } from "../models/types";

const AllAirports = () => {
  const [addAirportModal, setAddAirportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [airportBox, setAirportBox] = useState<Airport[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchquery, setSearchQuery] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  useEffect(() => {
    // Set a timeout to update the debouncedSearchText after 500ms
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchquery);
    }, 500);

    // Clear the timeout if searchText changes (i.e., the user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [searchquery]);

  const onCloseAddAirportModal = useCallback(() => {
    setAddAirportModal(false);
  }, [addAirportModal]);

  const getData = async (currentPage: number, searchquery: string) => {
    setIsLoading(true);
    const response = await getAdminAirports(currentPage, searchquery);
    const tPages = Math.ceil(Number(response?.total) / 10);
    setTotalPages(tPages == 0 ? 1 : tPages);
    setCurrentPage(response.current_page);

    setAirportBox(response.data);
    setIsLoading(false);

  };

  React.useEffect(() => {
    getData(currentPage, searchquery);
  }, [currentPage, debouncedSearchText]);
  return (
    <>
      {addAirportModal && (
        <AddAirport onRefresh={() => getData(currentPage, searchquery)} onClose={onCloseAddAirportModal} />
      )}
      <div className="w-full p-[20px] rounded-[20px] bg-white mt-6">
        <div className="flex md:flex-row flex-col  justify-between md:items-center flex-wrap gap-3 ">
          <div className="flex items-center md:gap-8 gap-4 flex-wrap row-gap-3 ">
            <h4 className="text-[20px] text-[#1C1C1C] font-[400] uppercase">
              All Airports
            </h4>
          </div>
          <div className="flex items-center flex-wrap gap-3 relative">
            <button
              onClick={() => {
                setAddAirportModal(true);
              }}
              className="bg-[#1C1C1C] rounded-[10px] text-[12px] py-2 px-4 text-[white]  text-center"
            >
              Add Airport
            </button>

            <div className="bg-[#FAFAFA] border rounded-[10px] py-1 px-2 border-[#F0F0F0] flex items-center gap-2">
              <input
                placeholder="Search"
                type="text"
                value={searchquery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
                className="shadow-none bg-[transparent] text-[#7A7A7A] text-[12px] font-[400] p-0 border-0 w-[100px]"
              />
              <button type="button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.90906 2C5.93814 2 4.98903 2.28791 4.18174 2.82733C3.37444 3.36674 2.74524 4.13343 2.37368 5.03045C2.00213 5.92746 1.90491 6.91451 2.09433 7.86677C2.28375 8.81904 2.75129 9.69375 3.43783 10.3803C4.12438 11.0668 4.99909 11.5344 5.95135 11.7238C6.90362 11.9132 7.89067 11.816 8.78768 11.4444C9.6847 11.0729 10.4514 10.4437 10.9908 9.63639C11.5302 8.8291 11.8181 7.87998 11.8181 6.90906C11.818 5.60712 11.3008 4.35853 10.3802 3.43792C9.45959 2.51731 8.211 2.00008 6.90906 2Z"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.5703 10.5723L13.9987 14.0007"
                    stroke="#7A7A7A"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="py-6 border-t border-b border-[#E3E3E3] mt-6 mb-6 flex flex-col gap-6">
              {airportBox.length > 0 ?
                airportBox.map((item, index) => (
                  <>
                    <AirportListCard
                      data={item}
                      index={index + 1}
                      onRefresh={() => {
                        getData(currentPage, searchquery);
                      }}
                    />
                  </>
                )) : <p className="text-center text-[#2EABE3] md:text-[20px] text-[16px]">No Reservations Found</p>}
            </div>
            <div className="mx-auto text-center w-fit">
              <Pagination totalPages={totalPages} currentPage={currentPage}
                onPage={(page) => {
                  setCurrentPage(page)
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1)
                }}
                onPrev={() => {
                  setCurrentPage(currentPage - 1)
                }} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllAirports;
