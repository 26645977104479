const reservationActions = {
  updateReservation: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload,
  }),
  updateAirPort: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { airport: payload },
  }),
  updateDays: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { days: payload },
  }),
  updateDailyRate: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { dailyRate: payload },
  }),
  updateStripeData: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { stripeData: payload },
  }),
  updateReservationResponse: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { reservationResponse: payload },
  }),
  updateTaxes: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { taxes: payload },
  }),
  updateCheckin: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { checkin: payload },
  }),
  updateCheckout: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { checkout: payload },
  }),
  updatePackage: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { package: payload },
  }),
  updateCarDetails: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { carDetails: payload },
  }),
  updatePaymentDetails: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { payment: payload },
  }),
  updateStripeResponse: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { stripe_response: payload },
  }),
  updateCoupon: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { coupon: payload },
  }),
};

export default reservationActions;
