import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import EditIcon from "../assets/images/edit-pen.svg";
import { GetHours } from "../service/AdminApi/AdminApi";
import { editHours } from "../service/AdminApi/AdminApi";
import DynamicInfoList from "./DynamicInfoList";
import LocationPicker from "../components/LocationPicker";
import { Partner } from "../models/types";
import { updatePartnerValues } from "../service/AdminApi/partnerApi";
import Spinner from "../components/Spinner";
type BusinessSecProps = {
  partnerData: Partner
}
const BusinessSec = ({ partnerData }: BusinessSecProps) => {
  const [showBusinessInfoSec, setShowBusinessInfoSec] = useState(true)
  const [showMap, setShowMap] = useState(true)
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [hoursLoading, setHoursLoading] = useState(false);
  const [editMonday, setEditMonday] = useState(false);
  const [editTuesday, setEditTuesday] = useState(false);
  const [editWednesday, setEditWednesday] = useState(false);
  const [editThursday, setEditThursday] = useState(false);
  const [editFriday, setEditFriday] = useState(false);
  const [editSaturday, setEditSaturday] = useState(false);
  const [editSunday, setEditSunday] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [hours, setHours] = useState<any>("");
  const [isLoading, setIsloading] = useState(false)

  const schema = yup
    .object({
      id: yup.string().required(),
      monday_start_time: yup.string().required(),
      monday_end_time: yup.string().required(),
      tuesday_start_time: yup.string().required(),
      tuesday_end_time: yup.string().required(),
      wednesday_start_time: yup.string().required(),
      wednesday_end_time: yup.string().required(),
      thursday_start_time: yup.string().required(),
      thursday_end_time: yup.string().required(),
      friday_start_time: yup.string().required(),
      friday_end_time: yup.string().required(),
      saturday_start_time: yup.string().required(),
      saturday_end_time: yup.string().required(),
      sunday_start_time: yup.string().required(),
      sunday_end_time: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getData = async (partnerId) => {
    const AllHours = await GetHours(partnerId);
    setHours(AllHours);
  };
console.log({errors});

  const onSubmit = async (data) => {
    const partnerId = partnerData?.id;
    setHoursLoading(true)
    editHours(data, partnerId, (success, resp) => {
      if (success) {
        getData(partnerId);
        showToast(globalDispatch, "Hours Updated");
      } else {
        alert("something went wrong!");
      }
      setHoursLoading(false)
    });
  };

  useEffect(function () {
    const partnerId = partnerData?.id;
    getData(partnerId);
  }, []);

  useEffect(
    function () {
      setValue("id", hours?.id);
      setValue("monday_start_time", hours?.monday_start_time);
      setValue("monday_end_time", hours?.monday_end_time);
      setValue("tuesday_start_time", hours?.tuesday_start_time);
      setValue("tuesday_end_time", hours?.tuesday_end_time);
      setValue("wednesday_start_time", hours?.wednesday_start_time);
      setValue("wednesday_end_time", hours?.wednesday_end_time);
      setValue("thursday_start_time", hours?.thursday_start_time);
      setValue("thursday_end_time", hours?.thursday_end_time);
      setValue("friday_start_time", hours?.friday_start_time);
      setValue("friday_end_time", hours?.friday_end_time);
      setValue("saturday_start_time", hours?.saturday_start_time);
      setValue("saturday_end_time", hours?.saturday_end_time);
      setValue("sunday_start_time", hours?.sunday_start_time);
      setValue("sunday_end_time", hours?.sunday_end_time);
    },
    [hours]
  );

  return (

    <>

      <div className="w-full bg-[white] rounded-[10px] p-4 mb-8">
        <div className="flex justify-between items-center">
          <h3 className='text-[#7A7A7A] text-[20px] uppercase mb-4'>Add Partner Location</h3>
          <div className="flex gap-4 items-center">
            <div className={`sec-collapse  ${showMap && 'down'} `}
              onClick={() => {
                setShowMap(!showMap)
              }}
            >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2083_15931)">
                  <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="#F2F2F2" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8837 7.1157C10.6494 6.88162 10.3317 6.75013 10.0004 6.75013C9.66915 6.75013 9.35145 6.88162 9.11707 7.1157L4.40207 11.829C4.16769 12.0635 4.03605 12.3815 4.03613 12.7131C4.03621 13.0446 4.16799 13.3626 4.40249 13.597C4.63698 13.8313 4.95498 13.963 5.28653 13.9629C5.61808 13.9628 5.93602 13.831 6.17041 13.5965L10.0004 9.76653L13.8304 13.5965C14.0661 13.8243 14.3817 13.9505 14.7095 13.9478C15.0372 13.9451 15.3508 13.8138 15.5827 13.5821C15.8146 13.3505 15.9462 13.037 15.9492 12.7093C15.9522 12.3815 15.8263 12.0657 15.5987 11.8299L10.8846 7.11487L10.8837 7.1157Z" fill="#2EABE3" />
                </g>
                <defs>
                  <clipPath id="clip0_2083_15931">
                    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        {showMap &&
          <div className="w-full">
            <LocationPicker isOpen={false}
              loading={isLoading}
              partner={partnerData}
              onClose={function (): void {
                throw new Error("Function not implemented.");
              }}
              onLocationSelected={function (location: { lat: number; lng: number; address: string; }): void {
                setIsloading(true)
                partnerData['latlng'] = `${location.lat},${location.lng}`
                updatePartnerValues(partnerData.id, { ...partnerData, }, (success, resp) => {
                  if (success) {
                    showToast(globalDispatch, "Updated");
                  } else {
                    alert('something went wrong!')
                  }
                  setIsloading(false)
                });
              }} />
          </div>
        }

      </div>

      <div className="w-full bg-[white] rounded-[10px] p-4">
        <div className="flex justify-between items-center">
          <h3 className='text-[#7A7A7A] text-[20px] uppercase'>BUSINESS INFO</h3>
          <div className="flex gap-4 items-center">
            <div className={`sec-collapse  ${showBusinessInfoSec && 'down'} `}
              onClick={() => {
                setShowBusinessInfoSec(!showBusinessInfoSec)
              }}
            >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2083_15931)">
                  <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="#F2F2F2" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8837 7.1157C10.6494 6.88162 10.3317 6.75013 10.0004 6.75013C9.66915 6.75013 9.35145 6.88162 9.11707 7.1157L4.40207 11.829C4.16769 12.0635 4.03605 12.3815 4.03613 12.7131C4.03621 13.0446 4.16799 13.3626 4.40249 13.597C4.63698 13.8313 4.95498 13.963 5.28653 13.9629C5.61808 13.9628 5.93602 13.831 6.17041 13.5965L10.0004 9.76653L13.8304 13.5965C14.0661 13.8243 14.3817 13.9505 14.7095 13.9478C15.0372 13.9451 15.3508 13.8138 15.5827 13.5821C15.8146 13.3505 15.9462 13.037 15.9492 12.7093C15.9522 12.3815 15.8263 12.0657 15.5987 11.8299L10.8846 7.11487L10.8837 7.1157Z" fill="#2EABE3" />
                </g>
                <defs>
                  <clipPath id="clip0_2083_15931">
                    <rect width="20" height="20" rx="10" transform="matrix(1 0 0 -1 0 20.5)" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        {showBusinessInfoSec &&
          <div className="md:grid md:grid-cols-2 flex flex-col-reverse gap-2">
            <div className="w-full flex flex-col gap-2">


              <DynamicInfoList data={partnerData} />
            </div>
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <div className="w-full bg-[#F3F3F3] rounded-[10px] p-[20px] pt-[10px] ">
                  <div className="flex justify-between items-center">
                    <h4 className="md:text-[12px] text-[12px] text-[#1C1C1C] font-[800] mb-[10px] uppercase">
                      Operating hours
                    </h4>
                  </div>
                  <div className="w-full bg-[#FFFFFF] p-[20px] rounded-[10px] border border-[#C5C5C5]">
                    <input {...register("id")} type="hidden" />

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Monday{" "}
                        <img
                          src={EditIcon}
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditMonday(!editMonday);
                          }}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.monday_start_time ? "border-red" : ""
                            }  ${editMonday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            }   `}
                          {...register("monday_start_time")}
                          type="time"
                        />

                        <p>-</p>
                        <input
                          className={`${errors.monday_end_time ? "border-red" : ""} ${editMonday
                            ? "opacity-100 pointer-events-all"
                            : "opacity-5 pointer-events-none"
                            }  `}
                          {...register("monday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Tuesday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditTuesday(!editTuesday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.tuesday_start_time ? "border-red" : ""
                            } ${editTuesday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("tuesday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.tuesday_start_time ? "border-red" : ""
                            } ${editTuesday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("tuesday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Wednesday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditWednesday(!editWednesday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.wednesday_start_time ? "border-red" : ""
                            } ${editWednesday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("wednesday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.wednesday_end_time ? "border-red" : ""
                            }  ${editWednesday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            }`}
                          {...register("wednesday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Thursday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditThursday(!editThursday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.thursday_start_time ? "border-red" : ""
                            } ${editThursday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("thursday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.thursday_end_time ? "border-red" : ""
                            } ${editThursday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("thursday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Friday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditFriday(!editFriday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.friday_start_time ? "border-red" : ""
                            } ${editFriday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("friday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.friday_end_time ? "border-red" : ""} ${editFriday
                            ? "opacity-100 pointer-events-all"
                            : "opacity-5 pointer-events-none"
                            } `}
                          {...register("friday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full mb-4 pb-4 border-b border-[#E3E3E3]">
                      <label className="flex w-full gap-2">
                        Saturday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditSaturday(!editSaturday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.saturday_start_time ? "border-red" : ""
                            } ${editSaturday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("saturday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.saturday_end_time ? "border-red" : ""
                            } ${editSaturday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("saturday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>

                    {/* time box */}
                    <div className="w-full ">
                      <label className="flex w-full gap-2">
                        Sunday{" "}
                        <img
                          className=" cursor-pointer"
                          onClick={() => {
                            setEditSunday(!editSunday);
                          }}
                          src={EditIcon}
                          alt=""
                        />{" "}
                      </label>
                      <div className="flex items-center gap-2 operate-time-field-holder">
                        <input
                          className={`${errors.sunday_start_time ? "border-red" : ""
                            } ${editSunday
                              ? "opacity-100 pointer-events-all"
                              : "opacity-5 pointer-events-none"
                            } `}
                          {...register("sunday_start_time")}
                          type="time"
                        />
                        <p>-</p>
                        <input
                          className={`${errors.sunday_end_time ? "border-red" : ""} ${editSunday
                            ? "opacity-100 pointer-events-all"
                            : "opacity-5 pointer-events-none"
                            } `}
                          {...register("sunday_end_time")}
                          type="time"
                        />
                      </div>
                    </div>
                  </div>
                  {!hoursLoading && <button
                    className="bg-[#1C1C1C] mt-2 rounded-[10px] py-3 px-2 text-[#FFFFFF] text-[12px] font-bold "
                    type="submit"
                  >
                    Save Changes
                  </button>}
                  {hoursLoading &&
                    <div
                      className=" mt-2 rounded-[10px] py-3 px-2 text-[#FFFFFF] text-[12px] font-bold "
                    >
                      <Spinner />
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
        }
      </div>

    </>






  );
};

export default BusinessSec;
