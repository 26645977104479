import React from "react";
import { useState } from "react";
import EditIcon from "../assets/images/edit-pen.svg";
import BinIcon from "../assets/images/bin-icon.svg";
import { GlobalContext, showToast } from "../globalContext";
import { useCallback } from "react";
import { deleteTax } from "../service/AdminApi/AdminApi";
import { UpdateTaxStatus } from "../service/AdminApi/AdminApi";
import EditTaxes from "../admin-modals/EditTaxes";
import DeleteModal from "../admin-components/DeleteModal";
import Loader from "../admin-components/Loader";
import { Partner, PartnerTax } from "../models/types";
import AddTaxe from "../admin-modals/AddTaxe";
import Spinner from "../components/Spinner";

type TaxesCardProps = {
  data: PartnerTax
  partner: Partner
  onRefresh: () => void
};

const TaxesCard = (props: TaxesCardProps) => {
  const { data, partner, onRefresh } = props


  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
  }, [showEditModal]);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModal(false);
  }, [deleteModal]);

  const onDelete = () => {
    setIsLoading(true);
    const partnerId = partner.id;
    deleteTax(data.id, partnerId, (success: any, resp: any) => {
      if (success) {
        onRefresh()
        showToast(globalDispatch, "Deleted");
        setIsLoading(false);
      } else {
        //   alert('something went wrong!')
      }
    });
  };

  const updateStatus = (status: any) => {
    setIsLoading(true);
    const partnerId = partner.id;
    const statusParams = { status, id: data.id, partnerId };
    UpdateTaxStatus(statusParams, (success, resp) => {
      if (success) {
        onRefresh()
        showToast(globalDispatch, "updated");
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } else {
        alert("something went wrong!");
      }
    });
  };
  return (
    <>
      {showEditModal && (

        <AddTaxe isEdit tax={data} closeModal={onCloseEditModal} onRefresh={() => { onRefresh() }} data={partner} />
      )}
      {deleteModal && (
        <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onDelete} />
      )}
      <div className="w-full py-[10px] px-[20px] rounded-[10px] flex md:flex-row flex-col md:items-center justify-between bg-[#F3F3F3]">
        <div className="flex flex-col">
          <p className="text-[#1C1C1C] text-[14px] font-[500]">
            {data.name}{" "}
          </p>
          <h5 className="text-[24px] font-[800] text-[#1C1C1C] ">
            {data.percentage !== "0" ? `${data.percentage}%` : `$${data.amount}`}
          </h5>
        </div>

        {isLoading && <Spinner />}

        <div className="flex items-center gap-4 md:ml-0 ml-auto">
          <label
            class={`inline-flex items-center cursor-pointer ${isLoading ? "opacity-5 pointer-events-none" : ""
              } `}
          >
            <input
              type="checkbox"
              onChange={() => {
                updateStatus(data.is_active == "1" ? "0" : "1");
              }}
              checked={data.is_active == "1" ? true : false}
              value=""
              class="sr-only peer"
            />
            <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4  dark:bg-[#797979] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2EABE3]"></div>
          </label>
          <button
            type="button"
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <img src={EditIcon} />
          </button>
          <button
            type="button"
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            <img src={BinIcon} />
          </button>
        </div>
      </div>
    </>
  );
};

export default TaxesCard;
