import React from 'react'
import ParkingTypeBox from './ParkingTypeBox'
import OptionBox from './OptionBox'
import { Partner, PartnerOptions } from '../models/types'
import { OPTIIONS_KEYS } from '../models/OptionsValues'
type ParkingTypesProps = {
    data: PartnerOptions
    onUpdate: (option: string, value: boolean) => void
}
const ParkingTypes = (props: ParkingTypesProps) => {
    const { data, onUpdate } = props
    return (
        <div className='w-full'>
            <p className='text-[#1C1C1C] text-[12px] font-[600]'>Types of Parking</p>
            <div className="grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4  bg-[white] p-2 rounded-[10px] w-full">

                <OptionBox
                    amenTitle='Covered Self Park'
                    item_key={OPTIIONS_KEYS.parkingTypes_coveredSelfPark} partner={data} optionType={'parking'}
                    onUpdate={onUpdate} />
                <OptionBox
                    amenTitle='Uncovered Self Park'
                    item_key={OPTIIONS_KEYS.parkingTypes_unCoveredSelfPark} partner={data} optionType={'parking'}
                    onUpdate={onUpdate} />
                <OptionBox
                    amenTitle='Covered Valet'
                    item_key={OPTIIONS_KEYS.parkingTypes_coveredVallet} partner={data} optionType={'parking'}
                    onUpdate={onUpdate} />
                <OptionBox
                    amenTitle='Uncovered Valet'
                    item_key={OPTIIONS_KEYS.parkingTypes_unCoveredVallet} partner={data} optionType={'parking'}
                    onUpdate={onUpdate} />

            </div>
        </div>
    )
}

export default ParkingTypes
