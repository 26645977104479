import React from 'react'
import { useState } from 'react';
import EditIcon from '../assets/images/edit-pen.svg'
import BinIcon from '../assets/images/bin-icon.svg'
import { useCallback } from "react";
import { UpdateCouponStatus } from '../service/AdminApi/AdminApi';
import { deleteCoupon } from '../service/AdminApi/AdminApi';
import EditCoupon from '../admin-modals/EditCouponModal';
import { GlobalContext, showToast } from "../globalContext";
import DeleteModal from './DeleteModal';
import Loader from './Loader';


const CouponCard = ({ OffPercentage, DiscountName, Discountcode, status, amount, id, getData }) => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [showEditModal, setShowEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onCloseEditModal = useCallback(() => {
        setShowEditModal(false);
    }, [showEditModal]);

    const onCloseDeleteModal = useCallback(() => {
        setDeleteModal(false);
    }, [deleteModal]);


    const onDelete = () => {
        setIsLoading(true)
        deleteCoupon(id, (success, resp) => {
            if (success) {
                getData()
                showToast(globalDispatch, "Deleted");
                setIsLoading(false)
            } else {
                alert('something went wrong!')
            }

        });
    }

    const updateStatus = (status) => {
        setIsLoading(true)
        const statusParams = { status, id }
        UpdateCouponStatus(statusParams, (success, resp) => {
            if (success) {
                getData()
                showToast(globalDispatch, "Updated");
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);

            } else {
                alert('something went wrong!')
            }

        });
    }


    return (
        <>

            {showEditModal &&

                <EditCoupon amount={amount} closeModal={onCloseEditModal} couponName={DiscountName} id={id} getData={getData} Discountcode={Discountcode} OffPercentage={OffPercentage} />
            }
            {deleteModal &&
                <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onDelete} />
            }
            <div className="w-full py-[10px] px-[20px] rounded-[10px] flex md:flex-row flex-col md:items-center justify-between bg-[#F3F3F3]">
                <div className="flex flex-col">
                    <p className='text-[#1C1C1C] text-[14px] font-[500]'>{DiscountName}</p>
                    <h5 className='text-[24px] font-[800] text-[#1C1C1C] mb-3'>{OffPercentage !== '0' ? `${OffPercentage}% Off` : amount}</h5>
                    <span className='w-fit block text-[#2eabe3] md:text-[24px] text-[18px] font-[800] border border-[#2eabe3] py-1 px-2'>{Discountcode}</span>
                </div>

                {isLoading &&
                    <Loader/>
                }


                <div className="flex items-center gap-4 md:ml-0 ml-auto">
                    <label class={`inline-flex items-center cursor-pointer ${isLoading ? 'opacity-5 pointer-events-none' : ''} `}>
                        <input
                            onChange={() => {
                                updateStatus(status == '1' ? '0' : '1')
                            }}
                            type="checkbox" value="" checked={status == '1' ? true : false} class="sr-only peer" />
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4  dark:bg-[#797979] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2eabe3]"></div>
                    </label>
                    <button type='button' onClick={() => { setShowEditModal(true) }}>
                        <img src={EditIcon} />
                    </button>
                    <button type='button'
                        onClick={() => {
                            setDeleteModal(true)
                        }}
                    >
                        <img src={BinIcon} />
                    </button>

                </div>
            </div>
        </>
    )
}

export default CouponCard
